@font-face {
  font-family: 'hans_kendrick';
  src: url("fonts/neuehanskendrick-regular-webfont.woff2") format("woff2"), url("fonts/neuehanskendrick-regular-webfont.woff") format("woff"), url("fonts/neuehanskendrick-regular-webfont.ttf") format("truetype"), url("fonts/neuehanskendrick-regular-webfont.svg#neue_hans_kendrickregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hans_kendrick_heavy';
  src: url("fonts/hanskendrick-heavy-webfont-webfont.woff2") format("woff2"), url("fonts/hanskendrick-heavy-webfont-webfont.woff") format("woff"), url("fonts/hanskendrick-heavy-webfont-webfont.ttf") format("truetype"), url("fonts/hanskendrick-heavy-webfont-webfont.svg#hans_kendrick_heavy") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FontAwesome';
  src: url("fonts/fontawesome-webfont.woff2") format("woff2"), url("fonts/fontawesome-webfont.woff") format("woff"), url("fonts/fontawesome-webfont.ttf") format("truetype"), url("fonts/fontawesome-webfont.svg#FontAwesome") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Smartphones (portrait and landscape) ----------- */
/*
P - portrait
L- landscape

devices breakpoints sneak-peak:
https://responsivedesign.is/develop/browser-feature-support/media-queries-for-common-device-breakpoints

technique:
https://css-tricks.com/media-queries-sass-3-2-and-codekit/

usage example:

.class {
	@include breakpoint(phone-l) { width: 60%; }
	@include breakpoint(ipad-three-less) { width: 60%; }
}

*/
.boxed {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding: 0 15px;
  box-sizing: border-box; }

.button-xl {
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: uppercase;
  font-family: "hans_kendrick_heavy", sans-serif;
  font-size: 21px;
  line-height: 1.2;
  border: 3px solid #000000;
  margin: 30px auto;
  padding: 27px 24px;
  padding-right: 100px;
  position: relative;
  cursor: pointer;
  max-width: 360px;
  color: #FFFFFF;
  border-color: white;
  background: #000000;
  position: relative; }
  .button-xl.button-xl-green {
    color: #FFFFFF;
    border-color: white;
    background: linear-gradient(45deg, #266f65 53%, #1f574e 85%); }
  .button-xl.button-xl-navy {
    color: #FFFFFF;
    border-color: white;
    background: linear-gradient(45deg, #38516b 53%, #2c4259 85%); }
  .button-xl.button-xl-orange {
    color: #FFFFFF;
    border-color: white;
    background: linear-gradient(29deg, #f7b35c 53%, #f49d52 85%); }
  .button-xl.button-xl-pink {
    color: #FFFFFF;
    border-color: white;
    background: linear-gradient(32deg, #ffcbd3 53%, #f5b1bb 85%); }
  .button-xl.button-xl-yellow {
    color: #000000;
    border-color: white;
    background: linear-gradient(135deg, #fdda31 53%, #f4eb10 85%); }
  .button-xl::after {
    font-family: "FontAwesome",sans-serif;
    content: "\f105";
    position: absolute;
    font-size: 53px;
    line-height: 1;
    top: 12px;
    right: 26px; }

.hidden {
  display: none; }

.full-width {
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px; }

.fr {
  float: right; }

.fl {
  float: left; }

.text-al {
  text-align: left; }

.text-ar {
  text-align: right; }

.bold {
  font-weight: 600; }

.btn {
  cursor: pointer; }
  .btn.form-btn {
    margin: 0;
    background: none;
    border: 1px solid #000000;
    outline: none;
    padding: 7px 14px 6px;
    font-size: 15px;
    color: #000000;
    background-color: #ffffff;
    text-transform: uppercase;
    font-family: "hans_kendrick_heavy", sans-serif; }
    .btn.form-btn.rounded {
      border-radius: 10px; }
    .btn.form-btn.revert {
      color: #ffffff;
      background-color: #000000; }

.coniqSignupButton {
  cursor: pointer;
  margin: 0;
  background: none;
  border: 1px solid #000000;
  border-radius: 0;
  outline: none;
  padding: 7px 14px 6px;
  font-size: 15px;
  color: #000000;
  background-color: #ffffff;
  text-transform: uppercase;
  font-family: "hans_kendrick_heavy", sans-serif; }

.capitalized {
  text-transform: uppercase; }

.desktop {
  display: none;
  /* Smartphones (portrait and landscape) ----------- */ }
  @media only screen and (min-width: 769px) {
    .desktop {
      display: block; }
      .desktop.inline-block {
        display: inline-block; } }

.mobile {
  display: none;
  /* Smartphones (portrait and landscape) ----------- */ }
  @media only screen and (max-width: 768px) {
    .mobile {
      display: block; }
      .mobile.inline-block {
        display: inline-block; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem; }

.row {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.row.reverse {
  -ms-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse; }

.col.reverse {
  -ms-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse; }

.col-xs, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  padding-right: 1rem;
  padding-left: 1rem; }

.col-xs {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%; }

.col-xs-1 {
  -ms-flex-preferred-size: 8.333%;
  flex-basis: 8.333%;
  max-width: 8.333%; }

.col-xs-2 {
  -ms-flex-preferred-size: 16.667%;
  flex-basis: 16.667%;
  max-width: 16.667%; }

.col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%; }

.col-xs-5 {
  -ms-flex-preferred-size: 41.667%;
  flex-basis: 41.667%;
  max-width: 41.667%; }

.col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  -ms-flex-preferred-size: 58.333%;
  flex-basis: 58.333%;
  max-width: 58.333%; }

.col-xs-8 {
  -ms-flex-preferred-size: 66.667%;
  flex-basis: 66.667%;
  max-width: 66.667%; }

.col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  -ms-flex-preferred-size: 83.333%;
  flex-basis: 83.333%;
  max-width: 83.333%; }

.col-xs-11 {
  -ms-flex-preferred-size: 91.667%;
  flex-basis: 91.667%;
  max-width: 91.667%; }

.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-1 {
  margin-left: 8.333%; }

.col-xs-offset-2 {
  margin-left: 16.667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.333%; }

.col-xs-offset-5 {
  margin-left: 41.667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.333%; }

.col-xs-offset-8 {
  margin-left: 66.667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.333%; }

.col-xs-offset-11 {
  margin-left: 91.667%; }

.start-xs {
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: start; }

.center-xs {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center; }

.end-xs {
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  text-align: end; }

.top-xs {
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start; }

.middle-xs {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center; }

.bottom-xs {
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end; }

.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.between-xs {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between; }

.first-xs {
  -ms-flex-order: -1;
  -webkit-box-ordinal-group: 0;
  order: -1; }

.last-xs {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1; }

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem; }
  .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem; }
  .col-sm {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-sm-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-sm-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-sm-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-sm-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-1 {
    margin-left: 8.333%; }
  .col-sm-offset-2 {
    margin-left: 16.667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.333%; }
  .col-sm-offset-5 {
    margin-left: 41.667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.333%; }
  .col-sm-offset-8 {
    margin-left: 66.667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.333%; }
  .col-sm-offset-11 {
    margin-left: 91.667%; }
  .start-sm {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start; }
  .center-sm {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center; }
  .end-sm {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end; }
  .top-sm {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start; }
  .middle-sm {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center; }
  .bottom-sm {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end; }
  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .between-sm {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between; }
  .first-sm {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1; }
  .last-sm {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1; } }

@media only screen and (min-width: 62em) {
  .container {
    width: 61rem; }
  .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem; }
  .col-md {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-md-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-md-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-md-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-md-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-1 {
    margin-left: 8.333%; }
  .col-md-offset-2 {
    margin-left: 16.667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.333%; }
  .col-md-offset-5 {
    margin-left: 41.667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.333%; }
  .col-md-offset-8 {
    margin-left: 66.667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.333%; }
  .col-md-offset-11 {
    margin-left: 91.667%; }
  .start-md {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start; }
  .center-md {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center; }
  .end-md {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end; }
  .top-md {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start; }
  .middle-md {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center; }
  .bottom-md {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end; }
  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .between-md {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between; }
  .first-md {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1; }
  .last-md {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1; } }

@media only screen and (min-width: 75em) {
  .container {
    width: 71rem; }
  .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem; }
  .col-lg {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-lg-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-lg-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-lg-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-lg-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-1 {
    margin-left: 8.333%; }
  .col-lg-offset-2 {
    margin-left: 16.667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.333%; }
  .col-lg-offset-5 {
    margin-left: 41.667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.333%; }
  .col-lg-offset-8 {
    margin-left: 66.667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.333%; }
  .col-lg-offset-11 {
    margin-left: 91.667%; }
  .start-lg {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start; }
  .center-lg {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center; }
  .end-lg {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end; }
  .top-lg {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start; }
  .middle-lg {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center; }
  .bottom-lg {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end; }
  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .between-lg {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between; }
  .first-lg {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1; }
  .last-lg {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1; } }

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); } }

@keyframes shake {
  0%, 100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); } }

@-webkit-keyframes shake-ie {
  0%, 100% {
    margin-top: -20px; }
  50% {
    margin-top: 0; } }

@keyframes shake-ie {
  0%, 100% {
    margin-top: -20px; }
  50% {
    margin-top: 0; } }

@keyframes slideUp {
  100% {
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1; } }

@keyframes rollOverUp {
  0% {
    background-position: 0 0; }
  50% {
    background-position: 0 -38px; }
  51% {
    display: none;
    background-position: 0 38px; }
  52% {
    display: block;
    background-position: 0 38px; }
  100% {
    background-position: 0 0; } }

.footer .slogan p.fade-in {
  opacity: 1;
  -webkit-transition: opacity 0.54s ease-in;
  -moz-transition: opacity 0.54s ease-in;
  -ms-transition: opacity 0.54s ease-in;
  -o-transition: opacity 0.54s ease-in;
  transition: opacity 0.54s ease-in; }

.fade-to-black {
  background-color: #000000 !important;
  color: #ffffff !important;
  -webkit-transition: all 0.54s ease-in;
  -moz-transition: all 0.54s ease-in;
  -ms-transition: all 0.54s ease-in;
  -o-transition: all 0.54s ease-in;
  transition: all 0.54s ease-in; }
  .fade-to-black a {
    color: #ffffff !important;
    -webkit-transition: all 0.54s ease-in;
    -moz-transition: all 0.54s ease-in;
    -ms-transition: all 0.54s ease-in;
    -o-transition: all 0.54s ease-in;
    transition: all 0.54s ease-in; }
  .fade-to-black h3, .fade-to-black ul, .fade-to-black p {
    background-color: #000000 !important;
    color: #ffffff !important;
    -webkit-transition: all 0.54s ease-in;
    -moz-transition: all 0.54s ease-in;
    -ms-transition: all 0.54s ease-in;
    -o-transition: all 0.54s ease-in;
    transition: all 0.54s ease-in; }

.falka-animate {
  display: block;
  position: relative;
  overflow: hidden; }
  .falka-animate svg {
    transform-origin: top right;
    transform: rotate3d(0, 0, 1, 90deg);
    display: block; }
  .falka-animate.rotate svg {
    transform: rotate3d(0, 0, 1, 0deg);
    -webkit-transition: transform 0.75s ease-in-out;
    -moz-transition: transform 0.75s ease-in-out;
    -ms-transition: transform 0.75s ease-in-out;
    -o-transition: transform 0.75s ease-in-out;
    transition: transform 0.75s ease-in-out; }

.falka-animate-backwards {
  display: block;
  position: relative;
  overflow: hidden; }
  .falka-animate-backwards svg {
    transform-origin: top right;
    transform: rotate3d(0, 0, 1, -90deg);
    display: block; }
  .falka-animate-backwards.rotate svg {
    transform: rotate3d(0, 0, 1, 0deg);
    -webkit-transition: transform 0.75s ease-in-out;
    -moz-transition: transform 0.75s ease-in-out;
    -ms-transition: transform 0.75s ease-in-out;
    -o-transition: transform 0.75s ease-in-out;
    transition: transform 0.75s ease-in-out; }

.slick-list, .slick-slider, .slick-track {
  position: relative;
  display: block; }

.slick-loading .slick-slide, .slick-loading .slick-track {
  visibility: hidden; }

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: 0; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-list, .slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  top: 0;
  left: 0; }

.slick-track:after, .slick-track:before {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir=rtl] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*# sourceMappingURL=slick.min.css.map */
/* usage example:
.class {
	@include breakpoint(phone-l) { width: 60%; }
}
*/
.App {
  position: relative; }

html {
  scroll-behavior: smooth; }

body {
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  font-family: "hans_kendrick", sans-serif;
  font-size: 14px;
  line-height: 19px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-weight: normal;
  /* Smartphones (portrait and landscape) ----------- */ }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    body {
      font-size: 14px; } }
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    body {
      font-size: 14px; } }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

a {
  text-decoration: none;
  color: #000000; }

h1,
h2,
h3,
h4,
h5,
h6,
header,
strong {
  font-family: "hans_kendrick_heavy", sans-serif, sans-serif; }

.kartaFrom input {
  font-size: 16px;
  background: none;
  outline: none;
  border: none;
  border-bottom: 2px solid #0a675f;
  width: 100%;
  display: inline-block;
  padding-bottom: 10px; }

.kartaFrom ::placeholder {
  color: #cccccc; }

.kartaFrom label {
  font-weight: bold;
  font-size: 16px; }

.kartaFrom .SignupCheckbox {
  vertical-align: middle;
  margin: 0 !important;
  height: 1.4em;
  width: 1.4em;
  font-size: inherit; }

.kartaFrom .SignupCheckboxLabel {
  font-weight: bold;
  vertical-align: middle;
  padding-left: 0.5em;
  line-height: 1.3em;
  display: inline;
  margin: 0; }

.kartaFrom .SignupField {
  -webkit-appearance: none;
  border: 1px solid #e3e3e3;
  font-family: inherit;
  font-size: inherit;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.3em;
  height: 2em;
  margin: 0;
  max-width: none; }

.main-header {
  display: block;
  background-color: transparent;
  padding: 0;
  width: 100%;
  height: 95px;
  position: relative;
  z-index: 1;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */ }
  .main-header .boxed {
    height: 80px;
    position: relative;
    padding-left: 16px;
    padding-right: 0; }
    @media only screen and (min-width: 992px) {
      .main-header .boxed {
        padding-left: 63px;
        padding-right: 0; } }
    .main-header .boxed > figure {
      float: left;
      height: auto;
      margin: 0;
      padding: 0;
      margin-top: 20px;
      margin-bottom: 0; }
  .main-header nav {
    float: right;
    margin: 0;
    display: inline-block; }
    .main-header nav.menu-top {
      margin-right: 0; }
      .main-header nav.menu-top > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 33px;
        margin-bottom: 0; }
        .main-header nav.menu-top > ul > li {
          display: inline-block;
          position: relative;
          background-color: transparent;
          border-bottom: 3px solid #000000; }
          .main-header nav.menu-top > ul > li span, .main-header nav.menu-top > ul > li a {
            display: block;
            color: #000000;
            font-size: 14px;
            line-height: 1;
            padding: 23px 30px 22px;
            background-color: transparent;
            cursor: pointer; }
          @media (max-width: 768px) {
            .main-header nav.menu-top > ul > li ul {
              display: block;
              height: auto;
              max-height: 0;
              opacity: 0;
              overflow: hidden;
              z-index: 12; } }
          @media (min-width: 769px) {
            .main-header nav.menu-top > ul > li ul {
              position: absolute;
              list-style: none;
              margin: 0;
              padding: 0;
              top: calc(100% + 21px);
              left: 40%;
              height: 1000px;
              width: auto;
              display: block;
              max-height: 0;
              opacity: 0;
              overflow: hidden;
              z-index: 12; }
              .main-header nav.menu-top > ul > li ul li {
                width: inherit; }
                .main-header nav.menu-top > ul > li ul li > span, .main-header nav.menu-top > ul > li ul li > a {
                  padding: 9px 12px 8px;
                  color: #000000;
                  white-space: nowrap;
                  text-transform: lowercase; }
                  .main-header nav.menu-top > ul > li ul li > span::before, .main-header nav.menu-top > ul > li ul li > a::before {
                    content: "+ "; }
                .main-header nav.menu-top > ul > li ul li:hover > span, .main-header nav.menu-top > ul > li ul li:hover > a {
                  color: #0a675f;
                  -webkit-transition: color 0.2s ease-in-out;
                  -moz-transition: color 0.2s ease-in-out;
                  -ms-transition: color 0.2s ease-in-out;
                  -o-transition: color 0.2s ease-in-out;
                  transition: color 0.2s ease-in-out; }
            .main-header nav.menu-top > ul > li[data-id="1513"] ul {
              left: auto;
              right: 0;
              text-align: right; }
              .main-header nav.menu-top > ul > li[data-id="1513"] ul li a::before {
                content: "";
                display: none; }
              .main-header nav.menu-top > ul > li[data-id="1513"] ul li a::after {
                content: "+ ";
                margin-left: 4px; } }
          .main-header nav.menu-top > ul > li.active > span, .main-header nav.menu-top > ul > li.active > a {
            color: #0a675f; }
          .main-header nav.menu-top > ul > li.active::after {
            content: "";
            position: absolute;
            display: block;
            width: 3px;
            height: 5px;
            bottom: 0;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            background-color: #000000; }
          .main-header nav.menu-top > ul > li.active ul {
            max-height: 300px;
            opacity: 1;
            -webkit-transition: all 0.12s ease-in;
            -moz-transition: all 0.12s ease-in;
            -ms-transition: all 0.12s ease-in;
            -o-transition: all 0.12s ease-in;
            transition: all 0.12s ease-in; }
            .main-header nav.menu-top > ul > li.active ul li {
              background-color: #ffffff; }
              .main-header nav.menu-top > ul > li.active ul li:first-child span, .main-header nav.menu-top > ul > li.active ul li:first-child a {
                padding-top: 22px; }
              .main-header nav.menu-top > ul > li.active ul li:last-child span, .main-header nav.menu-top > ul > li.active ul li:last-child a {
                padding-bottom: 21px; }
          @media (max-width: 768px) {
            .main-header nav.menu-top > ul > li.active {
              border-bottom: 3px solid #0a675f; }
              .main-header nav.menu-top > ul > li.active::after {
                content: "";
                position: absolute;
                display: block;
                width: 3px;
                height: 5px;
                bottom: 0;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                background-color: #0a675f !important; }
              .main-header nav.menu-top > ul > li.active > span {
                border-bottom: 3px solid #0a675f;
                position: relative; }
                .main-header nav.menu-top > ul > li.active > span::after {
                  content: "";
                  position: absolute;
                  display: block;
                  width: 3px;
                  height: 5px;
                  bottom: -8px;
                  left: 50%;
                  right: auto;
                  background-color: #0a675f; }
              .main-header nav.menu-top > ul > li.active ul {
                padding-top: 8px;
                padding-bottom: 8px; }
                .main-header nav.menu-top > ul > li.active ul li a {
                  padding: 16px 30px 15px; } }
          @media only screen and (min-width: 769px) {
            .main-header nav.menu-top > ul > li:first-child > span, .main-header nav.menu-top > ul > li:first-child > a {
              padding-left: 0; } }
          .main-header nav.menu-top > ul > li:first-child.active::after {
            content: "";
            position: absolute;
            display: block;
            width: 3px;
            height: 5px;
            bottom: 0;
            left: calc(50% - 15px);
            right: auto;
            background-color: #000000; }
            @media (max-width: 768px) {
              .main-header nav.menu-top > ul > li:first-child.active::after {
                left: 50%; } }
          @media (min-width: 769px) {
            .main-header nav.menu-top > ul > li:last-child > span, .main-header nav.menu-top > ul > li:last-child > a {
              padding-right: 0; } }
          .main-header nav.menu-top > ul > li:last-child.active::after {
            content: "";
            position: absolute;
            display: block;
            width: 3px;
            height: 5px;
            bottom: 0;
            right: calc(50% - 15px);
            left: auto;
            background-color: #000000; }
  .main-header .search-container {
    top: 30px; }
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    .main-header {
      height: auto;
      float: left; }
      .main-header .boxed > figure {
        width: 100%;
        text-align: center; }
      .main-header nav {
        width: 100%;
        display: block; }
        .main-header nav.menu-top {
          margin-right: 0; } }
  @media only screen and (max-width: 768px) {
    .main-header {
      float: left;
      height: auto;
      position: relative;
      z-index: 10;
      margin-bottom: 46px; }
      .main-header .mobile-menu-btn {
        position: absolute;
        width: 35px;
        height: 35px;
        top: 34px;
        right: 15px; }
        .main-header .mobile-menu-btn svg {
          display: block;
          width: 100%;
          height: auto; }
      .main-header .boxed {
        height: auto;
        position: relative;
        padding-left: 0;
        padding-right: 0; }
        .main-header .boxed > figure {
          float: left;
          width: 219px;
          margin: 27px auto 26px;
          padding-left: 16px; }
          .main-header .boxed > figure svg {
            width: 100%;
            height: auto;
            display: block; }
      .main-header .menus-container {
        float: left;
        margin: 0;
        display: block;
        max-height: 0;
        width: 100%;
        overflow: hidden; }
        .main-header .menus-container.expanded {
          max-height: 1200px;
          -webkit-transition: max-height 0.6s ease-in-out;
          -moz-transition: max-height 0.6s ease-in-out;
          -ms-transition: max-height 0.6s ease-in-out;
          -o-transition: max-height 0.6s ease-in-out;
          transition: max-height 0.6s ease-in-out; }
      .main-header nav {
        width: 100%;
        margin: 0;
        display: block;
        margin-bottom: 60px; }
        .main-header nav.menu-top {
          margin-right: 0;
          text-align: center; }
          .main-header nav.menu-top ul {
            list-style: none;
            margin: 0;
            padding: 0; }
            .main-header nav.menu-top ul li {
              display: block;
              text-align: center; } }

.page.content {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 25px;
  position: relative;
  z-index: 0; }
  .page.content > h2 {
    text-transform: uppercase;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.content > h2 {
        text-align: center;
        padding-left: unset; } }
  .page.content .boxed > h2 {
    margin-top: 130px;
    text-transform: uppercase;
    padding-left: 63px;
    font-size: 36px;
    line-height: 1;
    margin-bottom: 42px;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.content .boxed > h2 {
        text-align: center;
        padding-left: unset; } }

.page .row {
  width: 100%; }
  .page .row .col {
    position: relative; }

.page .section {
  display: block;
  min-height: 400px;
  width: 100%; }

.page > .boxed {
  padding: 0; }

.page .cover-row-container .col {
  padding: 0; }

.page .cover-row-container button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  border: none;
  display: block; }

.page .hd-row-container {
  background-color: #ffffff;
  margin-bottom: 26px;
  /* Smartphones (portrait and landscape) ----------- */ }
  .page .hd-row-container h2 {
    margin: 0;
    padding: 22px 118px 21px;
    font-size: 40px;
    line-height: 1;
    text-transform: uppercase; }
  .page .hd-row-container + .row {
    margin-bottom: 57px; }
  @media only screen and (max-width: 768px) {
    .page .hd-row-container h2 {
      padding: 22px 0 21px;
      text-align: center;
      line-height: 1.2; } }

.page.home .slider-container {
  width: 100%;
  display: flex; }

.page.home .search-row-container {
  margin: 0;
  margin-top: -3px;
  z-index: 10;
  position: relative;
  background-color: #ffffff;
  /* Smartphones (portrait and landscape) ----------- */ }
  .page.home .search-row-container .results {
    left: -23px;
    width: calc(100% + 71px); }
  .page.home .search-row-container .col.label label {
    text-transform: uppercase;
    padding: 29px 37px;
    display: inline-block;
    line-height: 1;
    color: #ffffff;
    background-color: #000000; }
  @media only screen and (max-width: 768px) {
    .page.home .search-row-container {
      margin: 0;
      margin-top: -4px; }
      .page.home .search-row-container .col {
        padding: 0; }
        .page.home .search-row-container .col.label {
          padding: 0; }
          .page.home .search-row-container .col.label label {
            padding: 22px 25px;
            display: block;
            text-align: center; } }

.page.home .links-row-container {
  margin: 0;
  margin-top: -1px;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */ }
  .page.home .links-row-container .col {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    /* Smartphones (portrait and landscape) ----------- */ }
    .page.home .links-row-container .col:nth-child(4n-2), .page.home .links-row-container .col:nth-child(4n-3) {
      flex-direction: row-reverse; }
    .page.home .links-row-container .col figure {
      margin: 0;
      padding: 0;
      width: 40%;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: top center;
      position: relative; }
      .page.home .links-row-container .col figure .fold {
        position: absolute;
        display: block;
        top: 0;
        width: 50%;
        height: 100%;
        max-width: 0;
        overflow: hidden;
        opacity: .86;
        z-index: 1;
        cursor: pointer; }
      .page.home .links-row-container .col figure img {
        display: block;
        width: 100%;
        height: 100%; }
    .page.home .links-row-container .col figcaption {
      text-align: center;
      width: 60%; }
      .page.home .links-row-container .col figcaption h2 {
        text-transform: uppercase;
        font-size: 20px;
        line-height: 23px;
        margin: 0 auto; }
      .page.home .links-row-container .col figcaption span {
        display: block; }
        .page.home .links-row-container .col figcaption span > span {
          display: inline; }
      .page.home .links-row-container .col figcaption p, .page.home .links-row-container .col figcaption a, .page.home .links-row-container .col figcaption span {
        text-transform: lowercase;
        font-family: "hans_kendrick_heavy", sans-serif;
        line-height: 1;
        font-size: 14px;
        margin: 0; }
      .page.home .links-row-container .col figcaption p {
        margin: 8px auto 63px; }
    .page.home .links-row-container .col.active figure .fold {
      max-width: 160px;
      -webkit-transition: all 0.36s ease-in-out;
      -moz-transition: all 0.36s ease-in-out;
      -ms-transition: all 0.36s ease-in-out;
      -o-transition: all 0.36s ease-in-out;
      transition: all 0.36s ease-in-out; }
    .page.home .links-row-container .col.active figcaption span > span {
      text-decoration: underline; }
    @media only screen and (min-width: 320px) and (max-width: 1280px) {
      .page.home .links-row-container .col figure .fold {
        right: 0;
        left: auto; }
      .page.home .links-row-container .col:nth-child(even) figure .fold {
        right: auto;
        left: 0; } }
    @media only screen and (min-width: 1281px) {
      .page.home .links-row-container .col figure .fold {
        right: 0;
        left: auto; }
      .page.home .links-row-container .col:nth-child(4n-2) figure .fold, .page.home .links-row-container .col:nth-child(4n-3) figure .fold {
        right: auto;
        left: 0; } }
    @media only screen and (max-width: 768px) {
      .page.home .links-row-container .col figure .fold {
        display: none; } }
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    .page.home .links-row-container .col {
      width: 100%;
      -webkit-flex-basis: 100%;
      flex-basis: 100%;
      max-width: 100%; }
      .page.home .links-row-container .col figcaption h2 {
        font-size: 30px;
        line-height: 36px; }
      .page.home .links-row-container .col:nth-child(4n-2), .page.home .links-row-container .col:nth-child(4n-3) {
        flex-direction: row; }
      .page.home .links-row-container .col:nth-child(even) {
        flex-direction: row-reverse; } }
  @media only screen and (max-width: 768px) {
    .page.home .links-row-container .col figcaption h2 {
      font-size: 24px; }
    .page.home .links-row-container .col figcaption p, .page.home .links-row-container .col figcaption a {
      font-size: 18px; }
    .page.home .links-row-container .col:nth-child(4n-2), .page.home .links-row-container .col:nth-child(4n-3) {
      flex-direction: row; }
    .page.home .links-row-container .col:nth-child(even) {
      flex-direction: row-reverse; } }

.page.home .hours-row-title {
  margin-top: 46px;
  margin-bottom: 33px;
  /* Smartphones (portrait and landscape) ----------- */ }
  .page.home .hours-row-title .title h2 {
    font-size: 22px;
    line-height: 1;
    padding: 50px 0 46px;
    text-align: right;
    padding-right: 186px;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0;
    margin-top: -1px;
    margin-right: 3.8%;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.home .hours-row-title .title h2 {
        padding-right: 0;
        padding: 22px 0 21px;
        text-align: center;
        margin-right: auto; } }
  @media only screen and (max-width: 768px) {
    .page.home .hours-row-title {
      margin: 0;
      padding: 0; }
      .page.home .hours-row-title .title {
        margin: 0;
        padding: 0; }
        .page.home .hours-row-title .title h2 {
          padding-right: 0;
          padding: 22px 0 21px;
          text-align: center;
          margin-right: auto;
          margin-bottom: 33px; } }

.page.home .find-us-row-container {
  margin-bottom: 71px;
  /* Smartphones (portrait and landscape) ----------- */ }
  .page.home .find-us-row-container .contact-us .newsletter {
    padding: 46px 20px 45px;
    cursor: pointer; }
    .page.home .find-us-row-container .contact-us .newsletter h3 {
      text-transform: uppercase;
      font-size: 22px;
      line-height: 1;
      margin: 0 auto 9px; }
    .page.home .find-us-row-container .contact-us .newsletter p {
      font-family: "hans_kendrick_heavy", sans-serif;
      font-size: 14px;
      margin: 0;
      line-height: 1; }
  .page.home .find-us-row-container .contact-us .social-media p {
    font-size: 22px;
    line-height: 1;
    text-transform: uppercase;
    font-family: "hans_kendrick_heavy", sans-serif;
    margin: 50px 0 35px; }
  .page.home .find-us-row-container .contact-us .social-media ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .page.home .find-us-row-container .contact-us .social-media ul li {
      display: inline-block;
      margin-right: 20px; }
      .page.home .find-us-row-container .contact-us .social-media ul li:last-child {
        margin-right: 0; }
  @media only screen and (max-width: 960px) {
    .page.home .find-us-row-container .contact-us {
      margin: 0;
      padding: 0; }
      .page.home .find-us-row-container .contact-us .col, .page.home .find-us-row-container .contact-us .row {
        margin: 0;
        padding: 0; }
      .page.home .find-us-row-container .contact-us .social-media ul {
        margin-bottom: 27px; } }
  .page.home .find-us-row-container .contact {
    padding-top: 44px;
    /* Smartphones (portrait and landscape) ----------- */ }
    .page.home .find-us-row-container .contact h3 {
      text-transform: uppercase;
      margin: 0 0 33px;
      font-size: 16px; }
    .page.home .find-us-row-container .contact p {
      font-size: 14px;
      margin: 0 0 33px; }
    @media only screen and (max-width: 768px) {
      .page.home .find-us-row-container .contact {
        margin: 0;
        padding: 0; }
        .page.home .find-us-row-container .contact .row {
          margin: 0;
          padding: 0; }
          .page.home .find-us-row-container .contact .row .col {
            padding: 0;
            margin-bottom: 71px; } }
  @media only screen and (max-width: 768px) {
    .page.home .find-us-row-container {
      padding: 0;
      margin: 0;
      margin-bottom: 71px; } }

.page.o-nas .cover-row-container {
  margin: 0; }
  .page.o-nas .cover-row-container h2 {
    margin: 0; }

.page.o-nas .content-row-container {
  margin: 100px 0 0;
  /* Smartphones (portrait and landscape) ----------- */ }
  .page.o-nas .content-row-container h3 {
    text-transform: uppercase;
    margin: 0;
    line-height: 31px;
    font-size: 28px;
    margin-bottom: 58px; }
  .page.o-nas .content-row-container p {
    padding-right: 0;
    margin: 0;
    margin-bottom: 20px; }
  .page.o-nas .content-row-container aside {
    display: block;
    width: 100%;
    height: 60vh;
    margin-top: -300px;
    margin-right: calc(-1rem - 1px);
    margin-left: auto;
    position: relative; }
    .page.o-nas .content-row-container aside .full-color {
      position: absolute;
      width: 100%;
      height: calc(100% - 199px);
      top: 199px;
      left: 0; }
  .page.o-nas .content-row-container .col {
    padding-right: 1rem;
    padding-left: 1rem; }
  @media only screen and (max-width: 768px) {
    .page.o-nas .content-row-container > .col > .row {
      padding: 0;
      margin: 0; } }
  @media only screen and (min-width: 992px) {
    .page.o-nas .content-row-container .col {
      margin-right: 0;
      padding-right: 0; }
    .page.o-nas .content-row-container .row {
      margin-right: 0;
      padding-right: 0; }
    .page.o-nas .content-row-container p {
      padding-right: 8.333%; } }

.page.godziny .hours-row-container {
  padding-left: calc(63px - 2em);
  margin-top: 52px;
  margin-bottom: 182px;
  /* Smartphones (portrait and landscape) ----------- */ }
  @media only screen and (max-width: 991px) and (min-width: 320px) {
    .page.godziny .hours-row-container {
      padding-left: 0; } }
  @media only screen and (max-width: 768px) {
    .page.godziny .hours-row-container {
      margin-bottom: 0; } }
  .page.godziny .hours-row-container > .col {
    padding-bottom: 12px; }
    .page.godziny .hours-row-container > .col.odd {
      color: #ffffff; }
    .page.godziny .hours-row-container > .col h3 {
      margin: 14px 36px 0;
      width: auto; }
      .page.godziny .hours-row-container > .col h3 img {
        display: block;
        width: 100%;
        height: auto; }
    .page.godziny .hours-row-container > .col ul li span {
      padding-left: 36px; }
    .page.godziny .hours-row-container > .col ul li header {
      padding-left: 36px; }
    @media only screen and (max-width: 991px) and (min-width: 320px) {
      .page.godziny .hours-row-container > .col ul li {
        width: calc(100% - 72px);
        margin-left: 36px;
        margin-right: 36px; }
        .page.godziny .hours-row-container > .col ul li pan {
          padding-left: 0; }
        .page.godziny .hours-row-container > .col ul li header {
          padding-left: 0; } }
    .page.godziny .hours-row-container > .col:nth-child(2) ul li:last-child {
      width: calc(100% - 36px);
      margin-left: 36px; }
      .page.godziny .hours-row-container > .col:nth-child(2) ul li:last-child span {
        padding-left: 0; }
      .page.godziny .hours-row-container > .col:nth-child(2) ul li:last-child header {
        padding-left: 0; }
      @media only screen and (max-width: 991px) and (min-width: 320px) {
        .page.godziny .hours-row-container > .col:nth-child(2) ul li:last-child {
          width: calc(100% - 72px);
          margin-left: 36px;
          margin-right: 36px; } }
    .page.godziny .hours-row-container > .col:last-child ul li:last-child {
      width: calc(100% - 36px); }
      @media only screen and (max-width: 991px) and (min-width: 320px) {
        .page.godziny .hours-row-container > .col:last-child ul li:last-child {
          width: calc(100% - 72px);
          margin-left: 36px;
          margin-right: 36px; } }

.page.lokalizacja .page-header.dojazd {
  margin-bottom: 52px; }
  .page.lokalizacja .page-header.dojazd h2 {
    margin-top: 0; }

.page.lokalizacja .mapa {
  margin: 0;
  padding: 0; }
  .page.lokalizacja .mapa .col {
    margin: 0;
    padding: 0;
    position: relative; }
    .page.lokalizacja .mapa .col figure {
      margin: 0 63px;
      display: block;
      box-sizing: border-box; }
      @media all and (-ms-high-contrast: none) {
        .page.lokalizacja .mapa .col figure {
          position: relative; } }
      @media all and (min-width: 320px) and (max-width: 991px) {
        .page.lokalizacja .mapa .col figure {
          margin-bottom: 40px; } }
      @media all and (-ms-high-contrast: none) and (min-width: 320px) and (max-width: 991px) {
        .page.lokalizacja .mapa .col figure {
          margin-bottom: 130px; } }
      .page.lokalizacja .mapa .col figure a {
        color: #ffffff;
        text-decoration-color: #ffffff; }
      .page.lokalizacja .mapa .col figure .svg-size-provider {
        display: none;
        width: 100%;
        height: 100%;
        max-width: 1392px;
        margin: 0 auto;
        opacity: 0; }
        @media all and (-ms-high-contrast: none) {
          .page.lokalizacja .mapa .col figure .svg-size-provider {
            display: block; } }
        @media all and (-ms-high-contrast: none) and (min-width: 992px) {
          .page.lokalizacja .mapa .col figure .svg-size-provider {
            max-width: 892px; } }
        @media all and (-ms-high-contrast: none) and (min-width: 320px) and (max-width: 991px) {
          .page.lokalizacja .mapa .col figure .svg-size-provider {
            max-width: 50%;
            height: 100%; } }
      .page.lokalizacja .mapa .col figure svg {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto; }
        @media all and (-ms-high-contrast: none) {
          .page.lokalizacja .mapa .col figure svg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            height: 100%; } }

.page.lokalizacja .rozklady-jazdy {
  margin: 0;
  padding-left: calc( 63px - 1em);
  padding-right: calc( 63px - 1em);
  /* Smartphones (portrait and landscape) ----------- */ }
  .page.lokalizacja .rozklady-jazdy header {
    font-family: "hans_kendrick", sans-serif;
    color: #ffffff; }
    .page.lokalizacja .rozklady-jazdy header .row, .page.lokalizacja .rozklady-jazdy header .col {
      margin: 0;
      padding: 0; }
    .page.lokalizacja .rozklady-jazdy header .kierunki {
      padding: 15px 12px; }
      .page.lokalizacja .rozklady-jazdy header .kierunki strong {
        font-family: "hans_kendrick_heavy", sans-serif; }
    .page.lokalizacja .rozklady-jazdy header figure {
      font-size: 46px;
      font-family: "hans_kendrick_heavy", sans-serif;
      line-height: 1;
      padding-right: 12px;
      margin: 0; }
  .page.lokalizacja .rozklady-jazdy main .hd {
    font-family: "hans_kendrick_heavy", sans-serif;
    font-size: 12px;
    margin: 0; }
    .page.lokalizacja .rozklady-jazdy main .hd .col span {
      display: block;
      padding: 7px 6px 6px; }
  .page.lokalizacja .rozklady-jazdy main .row-hour {
    font-size: 12px;
    margin: 0 0 3px; }
    .page.lokalizacja .rozklady-jazdy main .row-hour strong {
      display: inline-block;
      margin-right: 1em;
      padding-left: 6px; }
  .page.lokalizacja .rozklady-jazdy footer {
    margin: 21px 0 62px; }
    .page.lokalizacja .rozklady-jazdy footer .col-md-12 {
      font-family: "hans_kendrick_heavy", sans-serif; }
  @media only screen and (max-width: 768px) {
    .page.lokalizacja .rozklady-jazdy {
      padding: 0; }
      .page.lokalizacja .rozklady-jazdy > .col {
        padding: 0; }
      .page.lokalizacja .rozklady-jazdy main .row-hour span {
        display: block; }
      .page.lokalizacja .rozklady-jazdy footer {
        padding: 0 20px; } }

.page.udogodnienia {
  margin-bottom: 170px;
  position: relative; }
  .page.udogodnienia .features {
    margin-left: 0;
    margin-right: 0;
    margin-top: 60px; }
    .page.udogodnienia .features > .feature {
      margin: 0;
      padding: 0;
      position: relative; }
      .page.udogodnienia .features > .feature .title {
        display: inline;
        position: static; }
        .page.udogodnienia .features > .feature .title h3 {
          text-transform: uppercase;
          font-family: "hans_kendrick", sans-serif;
          font-size: 14px;
          line-height: 17px;
          position: relative;
          z-index: 1;
          padding-left: 2em;
          padding-right: 2em; }
        .page.udogodnienia .features > .feature .title .mask {
          display: block;
          position: absolute;
          top: 0;
          height: 100%;
          width: 58.333%;
          z-index: 0; }
      .page.udogodnienia .features > .feature .modal {
        position: absolute;
        box-sizing: border-box;
        left: 33.3%;
        top: 56px;
        padding: 47px 65px 47px 111px;
        background-color: rgba(255, 255, 255, 0.87);
        z-index: 2;
        width: 116%;
        min-height: 246px;
        height: auto;
        box-shadow: rgba(0, 0, 0, 0.08) 0 0 24px; }
        .page.udogodnienia .features > .feature .modal > nav {
          cursor: pointer;
          position: absolute;
          right: 12px;
          top: 7px;
          z-index: 1;
          font-size: 21px; }
        .page.udogodnienia .features > .feature .modal a {
          text-decoration: underline; }
        .page.udogodnienia .features > .feature .modal figure {
          display: block;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 74px;
          height: 182px; }
        .page.udogodnienia .features > .feature .modal h3 {
          text-align: center;
          font-size: 21px;
          line-height: 1;
          margin-top: 0;
          margin-bottom: 55px;
          text-transform: uppercase; }
          @media only screen and (max-width: 767px) {
            .page.udogodnienia .features > .feature .modal h3 {
              margin-top: 37px;
              margin-bottom: 38px; } }
        .page.udogodnienia .features > .feature .modal p {
          text-align: center;
          font-size: 16px;
          line-height: 18px;
          margin: 0; }
        @media only screen and (min-width: 769px) {
          .page.udogodnienia .features > .feature .modal.third {
            left: auto;
            right: 15%; } }
      @media only screen and (max-width: 767px) {
        .page.udogodnienia .features > .feature .modal {
          position: relative;
          background-color: #ffffff;
          top: 0;
          left: 0;
          padding: 1em 36px 5em;
          min-height: auto;
          width: 100%;
          text-align: center;
          box-sizing: border-box; }
          .page.udogodnienia .features > .feature .modal h4 {
            margin-bottom: 20px; }
          .page.udogodnienia .features > .feature .modal figure {
            display: none; }
        .page.udogodnienia .features > .feature.active > .row {
          height: 270px; } }
      .page.udogodnienia .features > .feature > .row {
        height: 270px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        flex-direction: row-reverse;
        background-position-x: right !important; }
        .page.udogodnienia .features > .feature > .row > .col {
          margin: 0;
          padding: 0; }
          .page.udogodnienia .features > .feature > .row > .col.icon svg {
            width: 60%;
            height: auto; }
      @media only screen and (max-width: 767px) {
        .page.udogodnienia .features > .feature > .row {
          flex-direction: row;
          background-position-x: left !important; }
          .page.udogodnienia .features > .feature > .row .mask {
            right: 0;
            left: auto; } }
      @media only screen and (min-width: 769px) and (max-width: 991px) {
        .page.udogodnienia .features > .feature:nth-child(4n-2) > .row, .page.udogodnienia .features > .feature:nth-child(4n-3) > .row {
          flex-direction: row;
          background-position-x: left !important; }
          .page.udogodnienia .features > .feature:nth-child(4n-2) > .row .mask, .page.udogodnienia .features > .feature:nth-child(4n-3) > .row .mask {
            right: 0;
            left: auto; } }
      @media only screen and (min-width: 992px) {
        .page.udogodnienia .features > .feature:nth-child(6n-3) > .row, .page.udogodnienia .features > .feature:nth-child(6n-4) > .row, .page.udogodnienia .features > .feature:nth-child(6n-5) > .row {
          flex-direction: row;
          background-position-x: left !important; }
          .page.udogodnienia .features > .feature:nth-child(6n-3) > .row .mask, .page.udogodnienia .features > .feature:nth-child(6n-4) > .row .mask, .page.udogodnienia .features > .feature:nth-child(6n-5) > .row .mask {
            right: 0;
            left: auto; } }

.page.plan-centrum .plan-container {
  max-width: 930px;
  z-index: 0;
  position: relative; }
  .page.plan-centrum .plan-container .svg-size-provider {
    display: none;
    width: 100%;
    height: 100%;
    max-width: 1392px;
    margin: 0 auto;
    opacity: 0;
    z-index: 9; }
    @media all and (-ms-high-contrast: none) {
      .page.plan-centrum .plan-container .svg-size-provider {
        display: block; } }
    @media all and (-ms-high-contrast: none) and (min-width: 992px) {
      .page.plan-centrum .plan-container .svg-size-provider {
        max-width: 892px; } }
    @media all and (-ms-high-contrast: none) and (min-width: 320px) and (max-width: 991px) {
      .page.plan-centrum .plan-container .svg-size-provider {
        max-width: 50%;
        height: 100%; } }
  .page.plan-centrum .plan-container > .col {
    padding: 0; }
  .page.plan-centrum .plan-container .stage {
    position: relative; }
    .page.plan-centrum .plan-container .stage svg {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 auto;
      pointer-events: none;
      z-index: 10; }
      @media all and (-ms-high-contrast: none) {
        .page.plan-centrum .plan-container .stage svg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          height: 100%; } }
      .page.plan-centrum .plan-container .stage svg * {
        pointer-events: none; }
      .page.plan-centrum .plan-container .stage svg .surface {
        pointer-events: visibleFill;
        cursor: pointer; }
      .page.plan-centrum .plan-container .stage svg polygon[dataid="inactive"] {
        pointer-events: none !important; }
    .page.plan-centrum .plan-container .stage .modal {
      left: -190px;
      top: 14%;
      /* Smartphones (portrait and landscape) ----------- */ }
      @media only screen and (max-width: 768px) {
        .page.plan-centrum .plan-container .stage .modal {
          position: relative;
          max-width: 1200px;
          width: calc(100% - 2em);
          left: 0;
          right: 0;
          margin: 0 1em; } }
      @media only screen and (min-width: 769px) and (max-width: 991px) {
        .page.plan-centrum .plan-container .stage .modal {
          left: -5px;
          top: 0; } }
      @media only screen and (min-width: 992px) and (max-width: 1359px) {
        .page.plan-centrum .plan-container .stage .modal {
          left: -80px;
          top: 0; } }
      @media only screen and (-ms-high-contrast: none) {
        .page.plan-centrum .plan-container .stage .modal {
          top: 7%; } }
      @media only screen and (-ms-high-contrast: none) and (min-width: 992px) and (max-width: 1359px) {
        .page.plan-centrum .plan-container .stage .modal {
          left: -60px; } }
      @media only screen and (-ms-high-contrast: none) and (min-width: 769px) and (max-width: 991px) {
        .page.plan-centrum .plan-container .stage .modal {
          top: -170px !important; } }
      @media only screen and (-ms-high-contrast: none) and (min-width: 320px) and (max-width: 768px) {
        .page.plan-centrum .plan-container .stage .modal {
          top: auto !important;
          bottom: -144px; } }
    .page.plan-centrum .plan-container .stage .pins-wrapper {
      /* Smartphones (portrait and landscape) ----------- */ }
      .page.plan-centrum .plan-container .stage .pins-wrapper svg#pins {
        position: absolute;
        bottom: -20px;
        left: 0;
        z-index: 11;
        width: 100%;
        height: 100%; }
      .page.plan-centrum .plan-container .stage .pins-wrapper.disabled {
        opacity: .3;
        -webkit-transition: opacity 0.2s ease-in-out;
        -moz-transition: opacity 0.2s ease-in-out;
        -ms-transition: opacity 0.2s ease-in-out;
        -o-transition: opacity 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out; }
        @media only screen and (-ms-high-contrast: none) {
          .page.plan-centrum .plan-container .stage .pins-wrapper.disabled svg {
            opacity: .3; } }
      @media only screen and (max-width: 768px) {
        .page.plan-centrum .plan-container .stage .pins-wrapper {
          display: none; } }
    .page.plan-centrum .plan-container .stage .legend {
      margin: 0;
      padding: 0;
      list-style: none;
      position: absolute;
      right: 0;
      bottom: 11%;
      text-align: left;
      z-index: 12;
      /* Smartphones (portrait and landscape) ----------- */ }
      @media only screen and (max-width: 768px) {
        .page.plan-centrum .plan-container .stage .legend {
          display: none; } }
      .page.plan-centrum .plan-container .stage .legend li {
        margin-top: 12px;
        vertical-align: middle;
        display: flex; }
        .page.plan-centrum .plan-container .stage .legend li i, .page.plan-centrum .plan-container .stage .legend li span {
          display: inline-block; }
        .page.plan-centrum .plan-container .stage .legend li svg {
          display: inline-block;
          width: 21px;
          height: 21px;
          position: static; }
        .page.plan-centrum .plan-container .stage .legend li span {
          margin-left: 18px; }
      @media all and (-ms-high-contrast: none) {
        .page.plan-centrum .plan-container .stage .legend {
          width: 300px;
          right: -156px; }
          .page.plan-centrum .plan-container .stage .legend li {
            display: block; } }

.page.plan-centrum .row.mobile-label-container {
  margin: 0; }

.page.plan-centrum .row.search-row-container {
  margin-bottom: 16px;
  margin-left: 0; }
  @media only screen and (min-width: 320px) and (max-width: 1279px) {
    .page.plan-centrum .row.search-row-container {
      margin-left: 0;
      margin-bottom: 0; } }
  .page.plan-centrum .row.search-row-container .search-container {
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.plan-centrum .row.search-row-container .search-container {
        right: 18px; } }
  .page.plan-centrum .row.search-row-container .col.label {
    padding-right: 0;
    white-space: nowrap; }
    .page.plan-centrum .row.search-row-container .col.label label {
      display: block;
      text-align: center;
      padding: 30px 38px 29px; }
    @media only screen and (min-width: 320px) and (max-width: 991px) {
      .page.plan-centrum .row.search-row-container .col.label {
        padding-right: 0; } }
  .page.plan-centrum .row.search-row-container .input {
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.plan-centrum .row.search-row-container .input {
        margin: 0; }
        .page.plan-centrum .row.search-row-container .input input[type=text] {
          text-indent: 2em; } }
    @media only screen and (min-width: 320px) and (max-width: 991px) {
      .page.plan-centrum .row.search-row-container .input {
        margin-left: 0; } }
    @media only screen and (min-width: 320px) and (max-width: 1279px) {
      .page.plan-centrum .row.search-row-container .input {
        height: 70px; } }
  .page.plan-centrum .row.search-row-container .results {
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.plan-centrum .row.search-row-container .results {
        position: static; } }

.page.plan-centrum .row.subtitle-container {
  margin: 0;
  padding: 0; }
  .page.plan-centrum .row.subtitle-container .col {
    margin: 0;
    padding: 0; }
  .page.plan-centrum .row.subtitle-container h2 {
    margin-left: 63px;
    font-size: 25px;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 0;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.plan-centrum .row.subtitle-container h2 {
        margin-top: 0;
        margin-left: 1em;
        margin-right: 1em; } }
    @media only screen and (min-width: 320px) and (max-width: 1279px) {
      .page.plan-centrum .row.subtitle-container h2 {
        font-size: 19px;
        margin-top: 30px; } }

.page.plan-centrum .row.plan-container {
  margin: 0 auto;
  margin-top: -50px; }
  @media only screen and (min-width: 320px) and (max-width: 1279px) {
    .page.plan-centrum .row.plan-container {
      margin-top: 50px; } }
  @media only screen and (-ms-high-contrast: none) and (min-width: 320px) and (max-width: 767px) {
    .page.plan-centrum .row.plan-container {
      margin-top: 200px; } }
  @media only screen and (-ms-high-contrast: none) and (min-width: 768px) and (max-width: 1279px) {
    .page.plan-centrum .row.plan-container {
      margin-top: 200px; } }
  @media only screen and (-ms-high-contrast: none) and (min-width: 1280px) {
    .page.plan-centrum .row.plan-container {
      margin-top: 0; } }

.page.plan-centrum .row.shops-row-container {
  padding: 0 112px;
  margin-bottom: 236px;
  margin-top: -33px;
  margin-left: 0;
  /* Smartphones (portrait and landscape) ----------- */ }
  .page.plan-centrum .row.shops-row-container .col, .page.plan-centrum .row.shops-row-container .row {
    padding: 0;
    margin: 0; }
  @media all and (-ms-high-contrast: none) {
    .page.plan-centrum .row.shops-row-container {
      margin-top: 0; } }
  @media only screen and (-ms-high-contrast: none) and (min-width: 320px) and (max-width: 961px) {
    .page.plan-centrum .row.shops-row-container {
      margin-top: 180px !important; } }
  @media only screen and (min-width: 769px) and (max-width: 1359px) {
    .page.plan-centrum .row.shops-row-container {
      padding: 0;
      margin: 0;
      margin-top: 20px; }
      .page.plan-centrum .row.shops-row-container .row, .page.plan-centrum .row.shops-row-container .col {
        margin: 0;
        padding: 0; } }
  @media only screen and (max-width: 768px) {
    .page.plan-centrum .row.shops-row-container {
      padding: 0;
      margin: 0;
      margin-top: 20px; }
      .page.plan-centrum .row.shops-row-container .row, .page.plan-centrum .row.shops-row-container .col {
        margin: 0;
        padding: 0; } }

.page.sklepy-i-uslugi {
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */ }
  .page.sklepy-i-uslugi h1 {
    margin: 0;
    margin-bottom: 83px; }
  .page.sklepy-i-uslugi .row.cover-row-container {
    margin-left: 0;
    margin-right: 0;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.sklepy-i-uslugi .row.cover-row-container h2 {
        margin: 0; }
      .page.sklepy-i-uslugi .row.cover-row-container + .hd-row-container {
        margin-top: -2px; } }
  .page.sklepy-i-uslugi .row.hd-row-container {
    margin-left: 0;
    margin-right: 0; }
    .page.sklepy-i-uslugi .row.hd-row-container + .row {
      margin-left: 0; }
      .page.sklepy-i-uslugi .row.hd-row-container + .row > .col > .row > .col:first-child h3::after {
        content: "";
        position: absolute;
        width: calc(100% + 64px);
        height: 1px;
        background-color: #000000;
        bottom: 0;
        left: 0; }
      .page.sklepy-i-uslugi .row.hd-row-container + .row > .col > .row > .col:not(:first-child) h3 span {
        position: relative;
        display: inline-block; }
        .page.sklepy-i-uslugi .row.hd-row-container + .row > .col > .row > .col:not(:first-child) h3 span::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: #000000;
          bottom: -20px;
          left: 0; }
  .page.sklepy-i-uslugi .row.services-row-container {
    margin-bottom: 266px; }
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    .page.sklepy-i-uslugi .row {
      margin: 0; }
      .page.sklepy-i-uslugi .row.hd-row-container + .row > .col > .row > .col:first-child h3::after {
        content: "";
        display: none; }
      .page.sklepy-i-uslugi .row.hd-row-container + .row > .col > .row > .col:first-child h3 span {
        position: relative;
        display: inline-block; }
        .page.sklepy-i-uslugi .row.hd-row-container + .row > .col > .row > .col:first-child h3 span::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: #000000;
          bottom: -20px;
          left: 0; }
      .page.sklepy-i-uslugi .row.hd-row-container + .row > .col > .row > .col:not(:first-child) h3 span {
        position: relative;
        display: inline-block; }
        .page.sklepy-i-uslugi .row.hd-row-container + .row > .col > .row > .col:not(:first-child) h3 span::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: #000000;
          bottom: -20px;
          left: 0; }
      .page.sklepy-i-uslugi .row.shops-row-container {
        margin-bottom: 88px; } }
  @media only screen and (max-width: 768px) {
    .page.sklepy-i-uslugi .row {
      margin: 0; }
      .page.sklepy-i-uslugi .row.hd-row-container + .row > .col > .row > .col:first-child h3::after, .page.sklepy-i-uslugi .row.hd-row-container + .row > .col > .row > .col:not(:first-child) h3::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #000000;
        bottom: 0;
        left: 0; }
      .page.sklepy-i-uslugi .row.shops-row-container {
        margin-bottom: 88px; } }

.page.shop {
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */ }
  .page.shop .cover-row-container h1 {
    padding: 0;
    margin: 0;
    margin-bottom: 90px;
    display: block; }
  .page.shop .cover-row-container figure {
    display: block;
    margin: 0;
    padding: 0;
    height: 100%;
    position: relative; }
    .page.shop .cover-row-container figure img {
      width: 100%;
      height: auto; }
    .page.shop .cover-row-container figure figcaption {
      position: absolute;
      width: 38%;
      padding: 51px 23px;
      top: 0;
      right: 0;
      box-sizing: border-box; }
      .page.shop .cover-row-container figure figcaption img {
        display: block;
        width: 100%;
        height: auto; }
  .page.shop .info-row-container {
    position: relative; }
    .page.shop .info-row-container .col {
      padding: 0; }
    .page.shop .info-row-container figure {
      margin: 0;
      margin-bottom: 300px; }
      .page.shop .info-row-container figure img {
        display: block;
        width: 100%;
        height: auto; }
    .page.shop .info-row-container h2 {
      font-size: 88px;
      line-height: 1;
      padding: 0;
      margin: 0;
      padding-left: 48px;
      padding-bottom: 27px; }
    .page.shop .info-row-container .text {
      padding: 62px 181px; }
      .page.shop .info-row-container .text > p {
        margin: 0; }
        .page.shop .info-row-container .text > p.text-extra {
          margin-top: 62px; }
          .page.shop .info-row-container .text > p.text-extra a {
            display: block; }
      .page.shop .info-row-container .text footer {
        margin: 90px auto 0 0; }
        .page.shop .info-row-container .text footer .row {
          margin: 0; }
        .page.shop .info-row-container .text footer svg {
          display: block;
          width: 30px;
          height: 30px; }
        .page.shop .info-row-container .text footer h3, .page.shop .info-row-container .text footer p {
          line-height: 1;
          margin: 0; }
        .page.shop .info-row-container .text footer h3 {
          text-transform: uppercase;
          padding-bottom: 20px; }
        .page.shop .info-row-container .text footer .list-item {
          margin-bottom: 6px; }
          .page.shop .info-row-container .text footer .list-item:last-child {
            margin-bottom: 0; }
    .page.shop .info-row-container .map {
      cursor: pointer;
      display: block;
      position: absolute;
      width: calc(25% - 16px);
      height: auto;
      background-color: #999999;
      bottom: 0;
      z-index: 11; }
      .page.shop .info-row-container .map img {
        display: block;
        width: 100%;
        height: auto; }
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    .page.shop .info-row-container {
      margin: 0; } }
  @media only screen and (max-width: 768px) {
    .page.shop .info-row-container {
      margin: 0; }
      .page.shop .info-row-container h2 {
        font-size: 48px;
        padding-left: 0; }
      .page.shop .info-row-container .text {
        padding: 32px 32px; }
      .page.shop .info-row-container .map {
        position: static;
        width: 100%; } }

.page.archiwum {
  padding-left: 0;
  padding-right: 0; }
  .page.archiwum .boxed h2 {
    padding-left: 63px;
    margin-top: 64px;
    margin-bottom: 44px; }
    @media only screen and (min-width: 769px) and (max-width: 1280px) {
      .page.archiwum .boxed h2 {
        margin-top: 250px; } }
  .page.archiwum .filter.date {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: 63px;
    margin-bottom: 30px;
    margin-top: 33px; }
    .page.archiwum .filter.date li {
      display: inline-block;
      margin-top: 0;
      margin-right: 12px;
      padding: 0;
      cursor: pointer; }
      .page.archiwum .filter.date li.active {
        font-family: "hans_kendrick_heavy", sans-serif; }
      .page.archiwum .filter.date li::after {
        content: "|";
        display: inline-block;
        padding-left: 12px; }
      .page.archiwum .filter.date li:first-child {
        margin-left: 0;
        padding-left: 0; }
      .page.archiwum .filter.date li:last-child {
        margin-right: 0;
        padding-right: 0; }
        .page.archiwum .filter.date li:last-child::after {
          display: none; }
  .page.archiwum .row {
    margin: 0;
    padding: 0; }
    .page.archiwum .row .col {
      margin: 0;
      padding: 0;
      position: relative;
      cursor: pointer; }
  .page.archiwum .archive-list-container {
    margin-bottom: 100px; }
    .page.archiwum .archive-list-container > .row .popup-overlay {
      right: -88px;
      left: auto;
      text-align: left;
      top: 21%; }
      .page.archiwum .archive-list-container > .row .popup-overlay .popup-content {
        padding-left: 57px;
        padding-right: 132px; }
        .page.archiwum .archive-list-container > .row .popup-overlay .popup-content figure {
          height: 258px; }
    .page.archiwum .archive-list-container > .row:nth-child(even) {
      flex-direction: row-reverse; }
      .page.archiwum .archive-list-container > .row:nth-child(even) .popup-overlay {
        right: auto;
        left: -88px;
        text-align: left;
        top: 21%; }
        .page.archiwum .archive-list-container > .row:nth-child(even) .popup-overlay .popup-content {
          padding-left: 132px;
          padding-right: 57px; }
          .page.archiwum .archive-list-container > .row:nth-child(even) .popup-overlay .popup-content > nav {
            right: 12px;
            left: auto; }
          .page.archiwum .archive-list-container > .row:nth-child(even) .popup-overlay .popup-content > figure {
            height: 258px;
            left: 0;
            right: auto; }
    .page.archiwum .archive-list-container > .row:hover nav span {
      text-decoration: underline; }
    @media only screen and (max-width: 991px) {
      .page.archiwum .archive-list-container > .row {
        background: #FFFFFF !important; } }
    .page.archiwum .archive-list-container h3, .page.archiwum .archive-list-container h4 {
      font-size: 34px;
      margin: 0;
      padding: 0;
      margin-bottom: 13px;
      text-transform: uppercase;
      line-height: 1.2; }
    .page.archiwum .archive-list-container h4 {
      font-size: 18px;
      margin-bottom: 13px; }
    .page.archiwum .archive-list-container nav {
      margin-top: 60px;
      font-size: 16px;
      font-family: "hans_kendrick_heavy", sans-serif;
      text-transform: lowercase; }
    .page.archiwum .archive-list-container figure {
      background-size: 100%;
      background-repeat: no-repeat;
      margin: 0;
      padding: 0; }
      .page.archiwum .archive-list-container figure img {
        width: 100%;
        height: auto;
        display: block;
        margin: 0;
        padding: 0; }
    .page.archiwum .archive-list-container figcaption {
      padding: 32px 16px; }
      @media only screen and (max-width: 991px) {
        .page.archiwum .archive-list-container figcaption {
          padding: 60px 2em; } }
  .page.archiwum .popup-content > nav {
    margin-top: 0;
    font-family: "hans_kendrick", sans-serif;
    font-size: 21px;
    text-transform: none; }
  @media only screen and (min-width: 992px) {
    .page.archiwum {
      padding-left: 63px;
      padding-right: 63px; }
      .page.archiwum > h2 {
        padding-left: 0; } }

.page.wydarzenia {
  padding-left: 0;
  padding-right: 0; }
  .page.wydarzenia .season-block {
    right: 0; }
  .page.wydarzenia .row {
    margin: 0;
    padding: 0; }
    .page.wydarzenia .row .col {
      margin: 0;
      padding: 0; }
  .page.wydarzenia > .row > .col {
    position: relative; }
    .page.wydarzenia > .row > .col .mask {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: auto;
      width: 100%;
      height: 100%;
      opacity: .94;
      z-index: 1;
      cursor: pointer;
      max-width: 0;
      /* Smartphones (portrait and landscape) ----------- */ }
      @media only screen and (max-width: 768px) {
        .page.wydarzenia > .row > .col .mask {
          background: none !important; } }
    .page.wydarzenia > .row > .col.active {
      cursor: pointer;
      /* Smartphones (portrait and landscape) ----------- */ }
      @media only screen and (max-width: 768px) {
        .page.wydarzenia > .row > .col.active {
          background: none !important; } }
      .page.wydarzenia > .row > .col.active .mask {
        max-width: 50%;
        -webkit-transition: max-width 0.3s ease-in-out;
        -moz-transition: max-width 0.3s ease-in-out;
        -ms-transition: max-width 0.3s ease-in-out;
        -o-transition: max-width 0.3s ease-in-out;
        transition: max-width 0.3s ease-in-out; }
    .page.wydarzenia > .row > .col:hover nav span:not(.shop-name) {
      text-decoration: underline; }
    .page.wydarzenia > .row > .col:nth-child(4n-2) .mask, .page.wydarzenia > .row > .col:nth-child(4n-3) .mask {
      right: 0;
      left: auto; }
    .page.wydarzenia > .row > .col:nth-child(4n-2) .row, .page.wydarzenia > .row > .col:nth-child(4n-3) .row {
      flex-direction: row; }
    .page.wydarzenia > .row > .col:nth-child(4n-2).odd .popup-overlay, .page.wydarzenia > .row > .col:nth-child(4n-3).odd .popup-overlay {
      left: calc(58.333% - 88px); }
      .page.wydarzenia > .row > .col:nth-child(4n-2).odd .popup-overlay .popup-content, .page.wydarzenia > .row > .col:nth-child(4n-3).odd .popup-overlay .popup-content {
        padding-right: 57px;
        padding-left: 132px;
        width: 600px; }
        .page.wydarzenia > .row > .col:nth-child(4n-2).odd .popup-overlay .popup-content > nav, .page.wydarzenia > .row > .col:nth-child(4n-3).odd .popup-overlay .popup-content > nav {
          right: 12px;
          left: auto; }
        .page.wydarzenia > .row > .col:nth-child(4n-2).odd .popup-overlay .popup-content figure, .page.wydarzenia > .row > .col:nth-child(4n-3).odd .popup-overlay .popup-content figure {
          left: 0;
          right: auto; }
    .page.wydarzenia > .row > .col:nth-child(4n-2):not(.odd) .popup-overlay, .page.wydarzenia > .row > .col:nth-child(4n-3):not(.odd) .popup-overlay {
      left: -302px; }
      .page.wydarzenia > .row > .col:nth-child(4n-2):not(.odd) .popup-overlay .popup-content, .page.wydarzenia > .row > .col:nth-child(4n-3):not(.odd) .popup-overlay .popup-content {
        padding-left: 57px;
        padding-right: 132px; }
        .page.wydarzenia > .row > .col:nth-child(4n-2):not(.odd) .popup-overlay .popup-content figure, .page.wydarzenia > .row > .col:nth-child(4n-3):not(.odd) .popup-overlay .popup-content figure {
          left: auto;
          right: 0; }
    .page.wydarzenia > .row > .col:nth-child(4n) .row, .page.wydarzenia > .row > .col:nth-child(4n-1) .row {
      flex-direction: row-reverse; }
    .page.wydarzenia > .row > .col:nth-child(4n).odd .popup-overlay, .page.wydarzenia > .row > .col:nth-child(4n-1).odd .popup-overlay {
      left: 58.5%; }
      .page.wydarzenia > .row > .col:nth-child(4n).odd .popup-overlay .popup-content, .page.wydarzenia > .row > .col:nth-child(4n-1).odd .popup-overlay .popup-content {
        padding-right: 57px;
        padding-left: 132px;
        width: 600px; }
        .page.wydarzenia > .row > .col:nth-child(4n).odd .popup-overlay .popup-content > nav, .page.wydarzenia > .row > .col:nth-child(4n-1).odd .popup-overlay .popup-content > nav {
          right: 12px;
          left: auto; }
        .page.wydarzenia > .row > .col:nth-child(4n).odd .popup-overlay .popup-content figure, .page.wydarzenia > .row > .col:nth-child(4n-1).odd .popup-overlay .popup-content figure {
          left: 0;
          right: auto; }
    .page.wydarzenia > .row > .col:nth-child(4n):not(.odd) .popup-overlay, .page.wydarzenia > .row > .col:nth-child(4n-1):not(.odd) .popup-overlay {
      right: calc(58.333% - 88px); }
      .page.wydarzenia > .row > .col:nth-child(4n):not(.odd) .popup-overlay .popup-content, .page.wydarzenia > .row > .col:nth-child(4n-1):not(.odd) .popup-overlay .popup-content {
        padding-left: 57px;
        padding-right: 132px; }
        .page.wydarzenia > .row > .col:nth-child(4n):not(.odd) .popup-overlay .popup-content figure, .page.wydarzenia > .row > .col:nth-child(4n-1):not(.odd) .popup-overlay .popup-content figure {
          left: auto;
          right: 0; }
  .page.wydarzenia figure {
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    cursor: pointer; }
    .page.wydarzenia figure img {
      width: 100%;
      height: auto;
      display: block;
      margin: 0;
      padding: 0; }
  .page.wydarzenia figcaption {
    line-height: 1;
    padding: 0; }
    .page.wydarzenia figcaption > span {
      display: block;
      padding: 0 2em 46px; }
    .page.wydarzenia figcaption h3 {
      font-size: 27px;
      margin: 60px auto 32px;
      width: 75%;
      /* Smartphones (portrait and landscape) ----------- */ }
      @media only screen and (max-width: 768px) {
        .page.wydarzenia figcaption h3 {
          font-size: 19px;
          line-height: 1.2; } }
    .page.wydarzenia figcaption p {
      font-size: 18px;
      line-height: 30px;
      width: 79%;
      margin: 0 auto 7px;
      /* Smartphones (portrait and landscape) ----------- */ }
      @media only screen and (max-width: 768px) {
        .page.wydarzenia figcaption p {
          font-size: 14px;
          line-height: 1.3; } }
    .page.wydarzenia figcaption nav p {
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      font-family: "hans_kendrick_heavy", sans-serif; }
    .page.wydarzenia figcaption nav span {
      font-size: 16px;
      font-family: "hans_kendrick_heavy", sans-serif;
      text-transform: lowercase;
      cursor: pointer; }
      .page.wydarzenia figcaption nav span.shop-name {
        font-size: 16px;
        display: block;
        margin-bottom: 20px; }
        .page.wydarzenia figcaption nav span.shop-name::before {
          content: "/ "; }
        .page.wydarzenia figcaption nav span.shop-name::after {
          content: " /"; }
    .page.wydarzenia figcaption.mobile-title {
      background-position: center 40px;
      font-size: 30px;
      line-height: 43px;
      text-transform: uppercase;
      font-family: "hans_kendrick_heavy", sans-serif;
      text-align: center;
      margin: 0;
      padding: 33px 0 12px;
      box-sizing: border-box; }
      .page.wydarzenia figcaption.mobile-title p {
        margin: 0;
        width: 100%; }
  .page.wydarzenia .to-archive {
    display: block;
    padding: 74px 0 73px;
    text-align: center; }
    .page.wydarzenia .to-archive a {
      text-decoration: underline;
      text-transform: lowercase;
      font-size: 22px; }
  @media only screen and (min-width: 992px) {
    .page.wydarzenia figcaption {
      padding: 32px 16px; }
      .page.wydarzenia figcaption > span {
        display: inline;
        padding: 0; }
    .page.wydarzenia > h2 {
      padding-left: 63px; } }

.page.news {
  /* Smartphones (portrait and landscape) ----------- */ }
  .page.news .cover-row-container {
    overflow: hidden;
    margin-bottom: 100px;
    margin-left: 0;
    margin-right: 0; }
    .page.news .cover-row-container figure {
      margin: 0;
      padding: 0; }
      .page.news .cover-row-container figure img {
        display: block;
        width: 100%;
        height: auto; }
  .page.news .row > .col {
    position: relative; }
    .page.news .row > .col ul {
      list-style: none;
      margin: 0;
      padding: 0; }
    .page.news .row > .col .panel {
      position: absolute;
      top: -63px;
      right: 0;
      height: 470px;
      width: 1200px; }
      @media only screen and (min-width: 320px) and (max-width: 991px) {
        .page.news .row > .col .panel {
          background: none !important; } }
    .page.news .row > .col > article {
      padding-left: 44px;
      min-height: 439px;
      /* Smartphones (portrait and landscape) ----------- */ }
      @media only screen and (max-width: 768px) {
        .page.news .row > .col > article {
          padding-left: 0; } }
    .page.news .row > .col.news-txt-content img {
      display: block;
      width: 100%;
      height: auto; }
    .page.news .row > .col.news-txt-content .legal {
      display: block;
      margin-top: 50px; }
  .page.news article {
    margin-bottom: 65px; }
    .page.news article h2, .page.news article h3 {
      line-height: 1;
      font-size: 36px;
      margin-bottom: 12px;
      padding-bottom: 0;
      margin-top: 0; }
    .page.news article > p {
      margin: 0;
      margin-bottom: 20px; }
  .page.news figcaption {
    color: #ffffff;
    padding: 50px 140px;
    padding-right: 140px;
    margin-bottom: 110px;
    margin-left: 24px; }
    .page.news figcaption header {
      font-size: 19px;
      line-height: 23px; }
    .page.news figcaption p {
      max-width: 246px;
      font-family: "hans_kendrick_heavy", sans-serif; }
  .page.news .button {
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    text-transform: uppercase;
    font-family: "hans_kendrick_heavy", sans-serif;
    font-size: 21px;
    line-height: 1.2;
    border: 3px solid #000000;
    margin: 30px auto;
    padding: 27px 24px;
    padding-right: 100px;
    position: relative;
    cursor: pointer;
    max-width: 360px;
    color: #FFFFFF;
    border-color: white;
    background: linear-gradient(45deg, #266f65 53%, #1f574e 85%);
    position: relative; }
    .page.news .button::after {
      font-family: "FontAwesome",sans-serif;
      content: "\f105";
      position: absolute;
      font-size: 53px;
      line-height: 1;
      top: 12px;
      right: 26px; }
  @media only screen and (max-width: 768px) {
    .page.news .row {
      margin: 0;
      padding: 0; }
      .page.news .row.cover-row-container {
        height: auto;
        margin-bottom: 34px; }
      .page.news .row .col {
        margin: 0;
        padding: 0; }
    .page.news article > p {
      padding: 0 32px; }
    .page.news figcaption {
      color: #ffffff;
      padding: 50px 32px;
      padding-right: 32px;
      margin-left: 0;
      margin-bottom: 0; } }

.page.promocja .header-promo {
  margin: 0;
  padding: 0;
  z-index: 1;
  position: relative; }
  .page.promocja .header-promo .col, .page.promocja .header-promo .row {
    margin: 0;
    padding: 0; }
    .page.promocja .header-promo .col.logo, .page.promocja .header-promo .row.logo {
      padding-right: 2em; }
  .page.promocja .header-promo > .col {
    margin-top: 80px;
    margin-bottom: 20px; }
  .page.promocja .header-promo img {
    width: 75%;
    max-width: 200px;
    height: auto;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.promocja .header-promo img {
        width: 66.6%; } }
  .page.promocja .header-promo h2 {
    text-transform: uppercase;
    font-size: 21px;
    line-height: 1.2;
    margin: 0;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.promocja .header-promo h2 {
        padding: 0 2rem;
        margin-top: 0; } }
    .page.promocja .header-promo h2 + p {
      margin-top: 7px;
      margin-bottom: 10px;
      line-height: 1; }
  .page.promocja .header-promo .daty {
    margin-bottom: 20px; }

.page.promocja .img-row-container {
  margin: 0;
  padding: 0;
  align-items: stretch; }
  .page.promocja .img-row-container .col, .page.promocja .img-row-container .row {
    margin: 0;
    padding: 0; }
  .page.promocja .img-row-container > .col {
    position: relative;
    min-height: 431px; }
    .page.promocja .img-row-container > .col .mask {
      position: absolute;
      z-index: 0;
      right: 0;
      top: 0;
      width: 1400px;
      height: 100%;
      display: block;
      /* Smartphones (portrait and landscape) ----------- */ }
      @media only screen and (max-width: 768px) {
        .page.promocja .img-row-container > .col .mask {
          background: none !important; } }
      .page.promocja .img-row-container > .col .mask.fit {
        width: 100%; }
    .page.promocja .img-row-container > .col img {
      position: relative;
      z-index: 1;
      padding-top: 25px;
      padding-bottom: 25px;
      display: inline-block;
      height: initial;
      max-height: 378px;
      width: auto;
      margin-left: 1rem;
      /* Smartphones (portrait and landscape) ----------- */ }
      @media only screen and (max-width: 768px) {
        .page.promocja .img-row-container > .col img {
          margin-left: 0;
          width: 100%;
          padding: 0; } }

.page.promocja .text-row-container {
  margin: 0;
  padding: 0;
  margin-top: 80px;
  margin-bottom: 33px;
  /* Smartphones (portrait and landscape) ----------- */ }
  @media only screen and (max-width: 768px) {
    .page.promocja .text-row-container {
      margin-top: 50px; } }
  .page.promocja .text-row-container .col, .page.promocja .text-row-container .row {
    margin: 0;
    padding: 0; }
  .page.promocja .text-row-container > .col {
    position: relative; }
    .page.promocja .text-row-container > .col .sidepic {
      display: block;
      width: 100%;
      height: 800px;
      background-position: top left;
      background-size: calc(100% - 1rem);
      background-repeat: no-repeat;
      margin-left: 1rem; }
    .page.promocja .text-row-container > .col article {
      padding-left: calc(44px + 1rem);
      /* Smartphones (portrait and landscape) ----------- */ }
      @media only screen and (max-width: 768px) {
        .page.promocja .text-row-container > .col article {
          padding-left: 1rem;
          padding-right: 1rem; } }

.page.promocja.gazetka .header-promo .logo {
  padding-right: 46px;
  padding-left: calc(1em + 11px); }
  .page.promocja.gazetka .header-promo .logo img {
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.promocja.gazetka .header-promo .logo img {
        margin-bottom: 20px; } }

.page.promocja .slider-row-container {
  margin-top: -352px;
  margin-bottom: 66px;
  padding: 0 2em;
  box-sizing: border-box;
  margin-right: 0;
  margin-left: 0;
  /* Smartphones (portrait and landscape) ----------- */ }
  @media only screen and (max-width: 768px) {
    .page.promocja .slider-row-container {
      margin-top: 33px; } }
  .page.promocja .slider-row-container .slick-arrow.slick-next {
    right: -25px; }
  .page.promocja .slider-row-container .slick-arrow.slick-prev {
    left: -25px; }

.page.fotoblog {
  position: relative; }
  .page.fotoblog .season-block {
    right: 0; }
  .page.fotoblog .fotoblog-row-container {
    margin: 0;
    margin-top: 60px;
    margin-bottom: 96px; }
    .page.fotoblog .fotoblog-row-container .col, .page.fotoblog .fotoblog-row-container .row {
      margin: 0;
      padding: 0; }
    .page.fotoblog .fotoblog-row-container > .col {
      padding: 0;
      position: relative;
      min-height: 350px;
      flex-direction: column; }
      .page.fotoblog .fotoblog-row-container > .col .group {
        flex-direction: column; }
        .page.fotoblog .fotoblog-row-container > .col .group.revert {
          flex-direction: column-reverse; }
      .page.fotoblog .fotoblog-row-container > .col .session {
        flex-direction: row;
        cursor: pointer;
        color: #000000;
        background-color: #ffffff; }
        .page.fotoblog .fotoblog-row-container > .col .session:hover {
          -webkit-transition: all 0.16s ease-in-out;
          -moz-transition: all 0.16s ease-in-out;
          -ms-transition: all 0.16s ease-in-out;
          -o-transition: all 0.16s ease-in-out;
          transition: all 0.16s ease-in-out;
          background-color: #0a675f;
          color: #ffffff; }
        .page.fotoblog .fotoblog-row-container > .col .session.title-on-right {
          flex-direction: row-reverse; }
        .page.fotoblog .fotoblog-row-container > .col .session img {
          margin: 0;
          padding: 0;
          display: block;
          width: 100%;
          height: auto; }
        .page.fotoblog .fotoblog-row-container > .col .session .col-xs-8 img {
          width: calc(100% + 1px); }
      .page.fotoblog .fotoblog-row-container > .col figure {
        margin: 0;
        padding: 0;
        display: block; }
      .page.fotoblog .fotoblog-row-container > .col figcaption {
        margin: 0;
        padding: 0; }
        .page.fotoblog .fotoblog-row-container > .col figcaption h3 {
          font-size: 15px;
          line-height: 1;
          margin-top: 26px;
          text-align: center;
          text-transform: uppercase; }

.page.csr .row, .page.csr .col {
  margin: 0;
  padding: 0; }

.page.csr .cover-row-container {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .page.csr .cover-row-container button {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    appearance: none;
    cursor: pointer; }
  .page.csr .cover-row-container h2 {
    padding: 0;
    margin: 0; }
  .page.csr .cover-row-container img {
    width: 100%;
    height: auto;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.csr .cover-row-container img {
        width: 160%; } }
  .page.csr .cover-row-container figcaption {
    margin: 0;
    padding: 0;
    position: absolute;
    top: calc(50% - 16px);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 1; }
    .page.csr .cover-row-container figcaption img {
      display: inline-block;
      width: 70%;
      height: auto; }

.page.csr .info {
  margin-top: -57px;
  margin-bottom: 45px;
  text-align: left;
  /* Smartphones (portrait and landscape) ----------- */ }
  @media only screen and (max-width: 768px) {
    .page.csr .info {
      margin-top: -4px;
      text-align: center; } }
  .page.csr .info .col {
    padding: 98px 73px;
    position: relative; }
    .page.csr .info .col figure {
      margin: 0;
      padding: 0;
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      height: 100%;
      width: 2000px;
      z-index: 0; }
    .page.csr .info .col p {
      position: relative;
      z-index: 1;
      font-size: 17px;
      line-height: 1.3; }

.page.csr .rules {
  margin-bottom: 45px; }
  .page.csr .rules > .col.link {
    cursor: pointer; }
  @media only screen and (max-width: 767px) {
    .page.csr .rules > .col .row-rule-item {
      flex-direction: row; } }
  @media only screen and (min-width: 769px) and (max-width: 991px) {
    .page.csr .rules > .col:nth-child(4n-2) .row-rule-item, .page.csr .rules > .col:nth-child(4n-3) .row-rule-item {
      flex-direction: row-reverse; } }
  @media only screen and (min-width: 992px) {
    .page.csr .rules > .col:nth-child(6n-3) .row-rule-item, .page.csr .rules > .col:nth-child(6n-4) .row-rule-item, .page.csr .rules > .col:nth-child(6n-5) .row-rule-item {
      flex-direction: row-reverse; } }
  .page.csr .rules .row-rule-item > .col.icon {
    text-align: center; }
    .page.csr .rules .row-rule-item > .col.icon svg {
      width: 50%;
      height: auto;
      margin-top: 80px;
      margin-bottom: 80px; }
  .page.csr .rules .row-rule-item > .col.txt h4 {
    margin-top: 30%;
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    font-family: "hans_kendrick_heavy", sans-serif;
    line-height: 1;
    /* Smartphones (portrait and landscape) ----------- */ }
    .page.csr .rules .row-rule-item > .col.txt h4 span {
      margin-left: 10px; }
    @media only screen and (max-width: 768px) {
      .page.csr .rules .row-rule-item > .col.txt h4 {
        margin: 30% 10px 0; } }
    .page.csr .rules .row-rule-item > .col.txt h4 + p {
      font-size: 16px;
      line-height: 19px;
      margin: 20px 20px 0;
      text-align: center; }
  .page.csr .rules .row-rule-item > .col.txt h3 {
    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.3;
    margin: 0;
    padding: 0;
    margin-top: calc(50% - 2em);
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.csr .rules .row-rule-item > .col.txt h3 {
        font-size: 32px; } }

.page.csr .participants .col, .page.csr .participants .row {
  vertical-align: top; }

.page.csr .participants .page-header {
  margin-bottom: 56px; }
  .page.csr .participants .page-header h2 {
    margin-top: 80px !important; }

.page.csr .participants .item {
  cursor: pointer; }
  .page.csr .participants .item .miniatura img {
    display: block;
    width: calc(100% + 1px);
    height: auto;
    margin-left: -1px; }
  .page.csr .participants .item .rotate {
    writing-mode: vertical-rl;
    text-orientation: mixed; }
    .page.csr .participants .item .rotate p {
      font-size: 15px;
      font-family: "hans_kendrick_heavy", sans-serif;
      line-height: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      text-align: center;
      margin-left: auto;
      margin-right: 23%; }
      @media only screen and (-ms-high-contrast: none) {
        .page.csr .participants .item .rotate p {
          transform: rotate(90deg) translate(126%);
          overflow: visible;
          margin-left: 21%; } }
  .page.csr .participants .item .arrow-container {
    position: relative; }
    .page.csr .participants .item .arrow-container .arrow {
      left: auto;
      right: -20px;
      position: absolute;
      top: calc(50% - 11px);
      background-color: transparent;
      z-index: 2;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 21px 0 21px 21px;
      border-color: transparent transparent transparent #FFFFFF; }
  @media only screen and (max-width: 767px) {
    .page.csr .participants .item .row {
      flex-direction: row; }
    .page.csr .participants .item .arrow {
      left: auto;
      right: -20px; } }
  @media only screen and (min-width: 769px) and (max-width: 991px) {
    .page.csr .participants .item:nth-child(4n-2) .row, .page.csr .participants .item:nth-child(4n-3) .row {
      flex-direction: row-reverse; }
    .page.csr .participants .item:nth-child(4n-2) .arrow, .page.csr .participants .item:nth-child(4n-3) .arrow {
      right: auto;
      left: -21px;
      transform: rotate(180deg); }
      @supports (-ms-ime-align: auto) {
        .page.csr .participants .item:nth-child(4n-2) .arrow, .page.csr .participants .item:nth-child(4n-3) .arrow {
          left: 0; } } }
  @media only screen and (min-width: 992px) {
    .page.csr .participants .item:nth-child(6n-3) .row, .page.csr .participants .item:nth-child(6n-4) .row, .page.csr .participants .item:nth-child(6n-5) .row {
      flex-direction: row-reverse; }
    .page.csr .participants .item:nth-child(6n-3) .arrow, .page.csr .participants .item:nth-child(6n-4) .arrow, .page.csr .participants .item:nth-child(6n-5) .arrow {
      right: auto;
      left: -21px;
      transform: rotate(180deg); }
      @supports (-ms-ime-align: auto) {
        .page.csr .participants .item:nth-child(6n-3) .arrow, .page.csr .participants .item:nth-child(6n-4) .arrow, .page.csr .participants .item:nth-child(6n-5) .arrow {
          left: 0; } } }

.page.csr .participants .slogan {
  text-align: right; }
  .page.csr .participants .slogan img {
    display: inline-block;
    width: 50%;
    height: auto; }

.page.csr .participants.articles header h2 {
  margin-top: 125px !important; }

.page.csr .partners {
  margin-top: 96px;
  margin-bottom: 118px; }
  .page.csr .partners .txt {
    padding: 58px 50px;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.csr .partners .txt {
        padding: 58px 1em 0; } }
    .page.csr .partners .txt h3 {
      font-size: 15px;
      line-height: 1.3;
      text-transform: uppercase;
      margin: 0; }
    .page.csr .partners .txt p {
      margin-top: 34px;
      line-height: 1.2;
      padding-right: 33%;
      box-sizing: border-box; }
  .page.csr .partners .list {
    padding: 58px 50px 30px;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.csr .partners .list {
        padding: 30px 1em 30px; } }
    .page.csr .partners .list p {
      font-family: "hans_kendrick_heavy", sans-serif;
      font-size: 15px;
      line-height: 1.3;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 34px; }
    .page.csr .partners .list img {
      display: block;
      width: 100%;
      height: auto; }

.page.event .boxed h2 {
  margin-top: 0;
  padding-left: 0;
  margin-bottom: 0; }
  .page.event .boxed h2 img {
    display: block;
    width: 100%;
    height: auto; }

.page.event .section-external-link .link-modal {
  text-align: center;
  padding: 30px 50px 30px 96px;
  min-height: 230px; }
  .page.event .section-external-link .link-modal .panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 72px;
    height: 230px; }
  .page.event .section-external-link .link-modal ol {
    text-align: left; }
  .page.event .section-external-link .link-modal a.submit {
    text-transform: uppercase;
    font-family: "hans_kendrick_heavy", sans-serif;
    margin: 30px auto 0; }
    .page.event .section-external-link .link-modal a.submit::after {
      content: "..."; }

.page.event .section-custom-image img {
  display: block;
  width: 100%;
  height: auto; }

.page.event .section-info {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.2; }
  .page.event .section-info .col {
    margin: 0;
    padding: 0;
    position: relative; }
  .page.event .section-info h3 {
    text-transform: uppercase; }
  .page.event .section-info .txt {
    padding: 45px 72px;
    padding-top: 0;
    margin-top: -34px;
    /* Smartphones (portrait and landscape) ----------- */ }
    .page.event .section-info .txt .mask {
      position: absolute;
      left: -72px;
      top: -38px;
      width: calc(100% + 52px);
      height: calc(100% + 34px);
      z-index: 0; }
    .page.event .section-info .txt .body {
      position: relative;
      z-index: 1; }
      .page.event .section-info .txt .body .hashtags {
        font-size: 22px;
        font-family: "hans_kendrick_heavy", sans-serif;
        line-height: 1.3;
        margin: 34px 0 66px 0; }
    @media only screen and (max-width: 768px) {
      .page.event .section-info .txt {
        margin-top: 0; }
        .page.event .section-info .txt .mask {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 0; } }
  .page.event .section-info .img {
    padding: 34px; }

.page.event .partners {
  margin: 0;
  padding: 0;
  margin-bottom: 100px; }
  .page.event .partners .col {
    margin: 0;
    padding: 0;
    position: relative; }
  .page.event .partners .list {
    padding: 81px 27px;
    text-align: center; }
    .page.event .partners .list h3 {
      padding: 0;
      font-size: 17px;
      display: block;
      text-align: left; }
    .page.event .partners .list img {
      display: block;
      width: 100%;
      height: auto; }

.page.event .section-harmonogram {
  padding: 0 72px 0;
  /* Smartphones (portrait and landscape) ----------- */ }
  @media only screen and (max-width: 768px) {
    .page.event .section-harmonogram {
      padding: 0 20px 0; } }

@keyframes ride {
  0% {
    margin-left: 8%; }
  100% {
    margin-left: 92%; } }

@keyframes rideIn {
  0% {
    opacity: 0;
    margin-left: -600px; }
  100% {
    opacity: 1;
    margin-left: 0; } }
  .page.event .section-harmonogram h3 {
    text-transform: uppercase;
    font-size: 21px;
    line-height: 1.2;
    margin: 124px 0 49px; }
  .page.event .section-harmonogram .bike figure {
    margin: 0;
    padding: 0;
    display: block;
    width: 15%; }
    .page.event .section-harmonogram .bike figure.long-ride {
      animation-name: ride;
      animation-duration: 120s;
      animation-iteration-count: 1; }
    .page.event .section-harmonogram .bike figure img {
      display: block;
      width: 100%;
      height: auto; }
      .page.event .section-harmonogram .bike figure img.ride-in {
        animation-name: rideIn;
        animation-duration: 2s;
        animation-iteration-count: 1; }
  .page.event .section-harmonogram .wykres img {
    display: block;
    width: 100%;
    height: auto; }

.page.event .section-mapy {
  padding: 0 72px 0;
  position: relative;
  /* Smartphones (portrait and landscape) ----------- */ }
  @media only screen and (max-width: 768px) {
    .page.event .section-mapy {
      padding: 0 20px 0; } }
  .page.event .section-mapy h3 {
    text-transform: uppercase;
    font-size: 21px;
    line-height: 1.2;
    margin: 124px 0 49px;
    position: relative;
    z-index: 1;
    margin-bottom: 34px; }
  .page.event .section-mapy > .row {
    padding-left: 68px;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.event .section-mapy > .row {
        padding-left: 0; } }
  .page.event .section-mapy .mask {
    position: absolute;
    left: -72px;
    top: -38px;
    width: calc(50% - 48px);
    height: calc(50% + 55px);
    z-index: 0;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .page.event .section-mapy .mask {
        display: none; } }
  .page.event .section-mapy .lista-map {
    position: relative;
    z-index: 1; }
  .page.event .section-mapy .mapa {
    margin-bottom: 24px; }
    .page.event .section-mapy .mapa img {
      width: 100%;
      height: auto;
      display: block;
      border: 1px solid #38516b; }
    .page.event .section-mapy .mapa nav a {
      display: inline-block;
      text-transform: uppercase;
      font-size: 15px;
      font-family: "hans_kendrick_heavy", sans-serif;
      padding-right: 18px;
      padding-top: 14px; }

.page.event .section-countdown {
  padding: 0;
  margin-bottom: -130px;
  margin-top: 66px; }
  .page.event .section-countdown .col {
    margin: 0;
    padding: 0; }
  .page.event .section-countdown div {
    font-family: "hans_kendrick_heavy", sans-serif;
    font-size: 21px;
    line-height: 1;
    margin: 0;
    padding: 0; }
    .page.event .section-countdown div.top {
      font-size: 30px;
      line-height: 30px;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 20px; }
    .page.event .section-countdown div.btm {
      font-size: 46px;
      margin: 0 0 104px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .page.event .section-countdown div.btm .counter-display {
        display: flex;
        align-items: center;
        justify-content: center; }
        .page.event .section-countdown div.btm .counter-display p {
          display: block;
          margin-top: 72px; }
          .page.event .section-countdown div.btm .counter-display p span {
            font-size: 42px; }
      .page.event .section-countdown div.btm.finished {
        padding-bottom: 67px;
        font-size: 32px; }

.page.event .rules {
  margin-top: 124px; }
  @media only screen and (min-width: 1281px) {
    .page.event .rules {
      margin-top: 124px; } }
  .page.event .rules .row, .page.event .rules .col {
    margin: 0;
    padding: 0; }
  .page.event .rules .row-rule-item > .col.txt h3 {
    margin-top: 0; }
  .page.event .rules .row-rule-item > .col.txt h4 {
    margin: 0;
    padding: 0;
    text-transform: lowercase;
    font-size: 19px;
    line-height: 1.2; }
  .page.event .rules .row-rule-item > .col.txt p {
    font-family: "hans_kendrick_heavy", sans-serif;
    text-transform: lowercase;
    font-size: 19px;
    margin: 5px 0; }
    .page.event .rules .row-rule-item > .col.txt p.no {
      font-size: 46px;
      line-height: 1; }

.page.event .gallery-photo-title {
  padding-top: 42px; }

.page.kontakt .row,
.page.kontakt .col,
.page.kontakt figure {
  margin: 0;
  padding: 0; }

.page.kontakt .boxed > h2 {
  margin-bottom: 33px; }
  @media only screen and (max-width: 1280px) and (min-width: 769px) {
    .page.kontakt .boxed > h2 {
      margin-top: 280px; } }

.page.kontakt .content-row-container {
  margin-bottom: 136px; }
  @media only screen and (max-width: 991px) {
    .page.kontakt .content-row-container {
      margin-bottom: 0; } }
  .page.kontakt .content-row-container > .col {
    min-height: 412px;
    position: relative;
    padding-left: 42px;
    padding-right: 2em; }
    .page.kontakt .content-row-container > .col section {
      margin-top: 66px;
      /* Smartphones (portrait and landscape) ----------- */ }
      .page.kontakt .content-row-container > .col section.lower {
        margin-top: 114px; }
      @media only screen and (max-width: 768px) {
        .page.kontakt .content-row-container > .col section figure img {
          width: 100%;
          height: auto; } }
      @media only screen and (max-width: 991px) {
        .page.kontakt .content-row-container > .col section {
          margin-top: 33px;
          margin-bottom: 66px; }
          .page.kontakt .content-row-container > .col section.lower {
            margin-top: 33px; } }
    .page.kontakt .content-row-container > .col a {
      display: block; }
    .page.kontakt .content-row-container > .col h3 {
      font-family: "hans_kendrick_heavy", sans-serif;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
      margin-bottom: 32px; }
    .page.kontakt .content-row-container > .col p {
      line-height: 19px;
      margin: 0;
      padding: 0;
      margin-bottom: 32px; }
      .page.kontakt .content-row-container > .col p strong {
        display: block;
        font-family: "hans_kendrick_heavy", sans-serif;
        text-transform: uppercase; }
    .page.kontakt .content-row-container > .col figure img {
      margin-top: 20px; }
    @media only screen and (max-width: 991px) {
      .page.kontakt .content-row-container > .col {
        padding-left: 2em;
        min-height: 412px; } }
    @media only screen and (max-width: 767px) {
      .page.kontakt .content-row-container > .col {
        min-height: 1px; } }
    .page.kontakt .content-row-container > .col.empty figure {
      position: absolute;
      top: 0;
      right: 0;
      width: 1600px;
      height: 100%; }
    @media only screen and (max-width: 991px) {
      .page.kontakt .content-row-container > .col.empty {
        display: none; } }

.footer .col, .footer .row {
  margin: 0;
  padding: 0; }

.footer .slogan {
  text-align: center;
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .footer .slogan p {
    color: #ffffff;
    font-size: 28px;
    line-height: 1;
    opacity: 0;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .footer .slogan p {
        font-size: 24px; } }

.footer .info > .row {
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 0 60px; }
  .footer .info > .row .ccol {
    display: inline-block;
    vertical-align: top; }
    .footer .info > .row .ccol a {
      color: #ffffff;
      margin: 0;
      padding: 0; }
      .footer .info > .row .ccol a:hover {
        text-decoration: underline; }
    .footer .info > .row .ccol nav {
      border-right: 1px solid #ffffff; }
      .footer .info > .row .ccol nav a {
        display: block;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 1.5;
        font-family: "hans_kendrick_heavy", sans-serif;
        padding: 18px 50px;
        white-space: nowrap; }
    .footer .info > .row .ccol section {
      padding-left: 50px;
      text-align: left; }
      .footer .info > .row .ccol section p {
        line-height: 1.5; }
      @media only screen and (min-width: 320px) and (max-width: 768px) {
        .footer .info > .row .ccol section {
          padding-left: 0;
          margin-bottom: 33px;
          text-align: center; }
          .footer .info > .row .ccol section:last-child {
            margin-bottom: 66px; } }
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      .footer .info > .row .ccol {
        width: 100%;
        display: block; } }
    @media only screen and (min-width: 769px) and (max-width: 991px) {
      .footer .info > .row .ccol nav a {
        padding: 18px 14px; }
      .footer .info > .row .ccol section {
        padding-left: 14px; } }
    @media only screen and (min-width: 992px) and (max-width: 1280px) {
      .footer .info > .row .ccol nav a {
        padding: 18px 25px; }
      .footer .info > .row .ccol section {
        padding-left: 25px; } }
  .footer .info > .row .col.end-sm {
    text-align: right; }
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      .footer .info > .row .col.end-sm {
        text-align: center; } }

.footer .info ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  .footer .info ul li {
    display: inline-block; }
  .footer .info ul.social-media {
    margin-top: 0;
    padding-right: 1em; }
    .footer .info ul.social-media li {
      margin-left: 21px; }
      .footer .info ul.social-media li svg {
        width: 50px;
        height: 50px;
        display: block; }
      .footer .info ul.social-media li:hover svg {
        animation: spin .5s linear 1; }

@keyframes spin {
  0% {
    transform: rotateY(0deg); }
  100% {
    transform: rotateY(-1turn); } }

.rules {
  margin-bottom: 45px; }
  .rules > .col.link {
    cursor: pointer; }
  @media only screen and (max-width: 767px) {
    .rules > .col .row-rule-item {
      flex-direction: row; } }
  @media only screen and (min-width: 769px) and (max-width: 991px) {
    .rules > .col:nth-child(4n-2) .row-rule-item, .rules > .col:nth-child(4n-3) .row-rule-item {
      flex-direction: row-reverse; } }
  @media only screen and (min-width: 992px) {
    .rules > .col:nth-child(6n-3) .row-rule-item, .rules > .col:nth-child(6n-4) .row-rule-item, .rules > .col:nth-child(6n-5) .row-rule-item {
      flex-direction: row-reverse; } }
  .rules .row-rule-item > .col.icon {
    text-align: center;
    min-height: 267px; }
    .rules .row-rule-item > .col.icon svg {
      width: 50%;
      height: auto;
      margin-top: 80px;
      margin-bottom: 80px; }
  .rules .row-rule-item > .col.txt h4 {
    margin-top: 30%;
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    font-family: "hans_kendrick_heavy", sans-serif;
    line-height: 1;
    /* Smartphones (portrait and landscape) ----------- */ }
    .rules .row-rule-item > .col.txt h4 span {
      margin-left: 10px; }
    @media only screen and (max-width: 768px) {
      .rules .row-rule-item > .col.txt h4 {
        margin: 30% 10px 0; } }
    .rules .row-rule-item > .col.txt h4 + p {
      font-size: 16px;
      line-height: 19px;
      margin: 20px 20px 0;
      text-align: center; }
  .rules .row-rule-item > .col.txt h3 {
    font-size: 26px;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.3;
    margin: 0;
    padding: 0;
    margin-top: calc(50% - 2em);
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .rules .row-rule-item > .col.txt h3 {
        font-size: 32px; } }

.tiles-section-title {
  margin-top: 0;
  padding-top: 21px; }
  .tiles-section-title figure {
    margin: 0;
    padding: 0;
    display: block;
    padding-left: 1rem; }
    .tiles-section-title figure svg {
      width: 75px;
      height: auto;
      display: block; }
  .tiles-section-title figcaption {
    padding: 0 1rem !important;
    margin: 19px 0 !important;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #000000; }

.gallery-row-container {
  padding-top: 0;
  margin-top: 0; }
  @media only screen and (min-width: 992px) {
    .gallery-row-container {
      margin-right: auto;
      margin-left: auto; }
      .gallery-row-container > .col {
        padding-right: 1rem;
        padding-left: 1rem; } }

.video-gallery-row-container {
  padding-left: 1rem;
  cursor: pointer; }
  .video-gallery-row-container .col {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 2rem;
    position: relative; }
    .video-gallery-row-container .col .icon {
      position: absolute;
      display: block;
      width: 120px;
      height: 120px;
      background-position: top center;
      background-size: 100%;
      top: calc(50% - 60px);
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      z-index: 1;
      opacity: .8; }
    .video-gallery-row-container .col img {
      display: block;
      width: 100%;
      height: auto; }

.section-tiles {
  padding: 0 72px;
  /* Smartphones (portrait and landscape) ----------- */ }
  @media only screen and (max-width: 768px) {
    .section-tiles {
      padding: 0 20px 0; } }
  .section-tiles .row, .section-tiles .col {
    margin: 0;
    padding: 0; }
  .section-tiles h3 {
    text-transform: uppercase;
    font-size: 21px;
    line-height: 1.2;
    margin: 124px 0 49px; }
  .section-tiles .tile-container {
    position: relative; }
    .section-tiles .tile-container .tile {
      text-transform: uppercase;
      font-size: 21px;
      cursor: pointer; }
      .section-tiles .tile-container .tile h4 {
        margin: 0;
        line-height: 1.2; }
      .section-tiles .tile-container .tile .title {
        position: relative;
        z-index: 3; }
      .section-tiles .tile-container .tile .icon {
        position: relative;
        z-index: 2; }
        .section-tiles .tile-container .tile .icon .mask {
          position: absolute;
          top: 0;
          width: 500%;
          max-width: 100%;
          height: 100%; }
        .section-tiles .tile-container .tile .icon figure {
          margin: 0;
          padding: 0;
          position: relative;
          z-index: 1; }
          .section-tiles .tile-container .tile .icon figure svg {
            width: 80px;
            height: 71px;
            display: inline-block; }
    .section-tiles .tile-container .link-modal {
      position: absolute;
      z-index: 5;
      width: 116%;
      min-height: 246px;
      height: auto;
      top: 30px;
      margin: 0; }
  .section-tiles .tile-row .tile-container {
    /* Smartphones (portrait and landscape) ----------- */ }
    .section-tiles .tile-row .tile-container .tile {
      flex-direction: row-reverse;
      /* Smartphones (portrait and landscape) ----------- */ }
      @media only screen and (max-width: 768px) {
        .section-tiles .tile-row .tile-container .tile {
          flex-direction: row !important; } }
      .section-tiles .tile-row .tile-container .tile .icon .mask {
        left: 0;
        right: auto; }
      .section-tiles .tile-row .tile-container .tile:hover .icon .mask {
        max-width: 150%;
        -webkit-transition: max-width 0.3s ease-in-out;
        -moz-transition: max-width 0.3s ease-in-out;
        -ms-transition: max-width 0.3s ease-in-out;
        -o-transition: max-width 0.3s ease-in-out;
        transition: max-width 0.3s ease-in-out;
        /* Smartphones (portrait and landscape) ----------- */ }
        @media only screen and (max-width: 768px) {
          .section-tiles .tile-row .tile-container .tile:hover .icon .mask {
            max-width: 100% !important; } }
    .section-tiles .tile-row .tile-container .link-modal {
      left: 75%;
      right: auto;
      padding: 47px 65px 47px 111px; }
      .section-tiles .tile-row .tile-container .link-modal .link-modal-close {
        right: 12px;
        left: auto; }
      .section-tiles .tile-row .tile-container .link-modal .square {
        display: block;
        position: absolute;
        top: 0;
        width: 74px;
        height: 217px; }
    .section-tiles .tile-row .tile-container:nth-child(3n-3) .link-modal {
      padding: 47px 111px 47px 65px;
      right: calc(100% - 74px);
      left: auto; }
      .section-tiles .tile-row .tile-container:nth-child(3n-3) .link-modal .link-modal-close {
        left: 12px;
        right: auto; }
      .section-tiles .tile-row .tile-container:nth-child(3n-3) .link-modal .square {
        right: 0;
        left: auto; }
    .section-tiles .tile-row .tile-container:nth-child(3n-1) .link-modal, .section-tiles .tile-row .tile-container:nth-child(3n-2) .link-modal {
      padding: 47px 65px 47px 111px;
      left: calc(50% - 74px);
      right: auto; }
      .section-tiles .tile-row .tile-container:nth-child(3n-1) .link-modal .link-modal-close, .section-tiles .tile-row .tile-container:nth-child(3n-2) .link-modal .link-modal-close {
        left: auto;
        right: 12px; }
      .section-tiles .tile-row .tile-container:nth-child(3n-1) .link-modal .square, .section-tiles .tile-row .tile-container:nth-child(3n-2) .link-modal .square {
        left: 0;
        right: auto; }
    .section-tiles .tile-row .tile-container:nth-child(6n-3) .tile, .section-tiles .tile-row .tile-container:nth-child(6n-4) .tile, .section-tiles .tile-row .tile-container:nth-child(6n-5) .tile {
      flex-direction: row; }
      .section-tiles .tile-row .tile-container:nth-child(6n-3) .tile .icon .mask, .section-tiles .tile-row .tile-container:nth-child(6n-4) .tile .icon .mask, .section-tiles .tile-row .tile-container:nth-child(6n-5) .tile .icon .mask {
        right: 0;
        left: auto; }
    .section-tiles .tile-row .tile-container:nth-child(6n-3):nth-child(3n-1) .link-modal, .section-tiles .tile-row .tile-container:nth-child(6n-3):nth-child(3n-2) .link-modal, .section-tiles .tile-row .tile-container:nth-child(6n-4):nth-child(3n-1) .link-modal, .section-tiles .tile-row .tile-container:nth-child(6n-4):nth-child(3n-2) .link-modal, .section-tiles .tile-row .tile-container:nth-child(6n-5):nth-child(3n-1) .link-modal, .section-tiles .tile-row .tile-container:nth-child(6n-5):nth-child(3n-2) .link-modal {
      padding: 47px 65px 47px 111px;
      left: calc(100% - 74px);
      right: auto; }
      .section-tiles .tile-row .tile-container:nth-child(6n-3):nth-child(3n-1) .link-modal .link-modal-close, .section-tiles .tile-row .tile-container:nth-child(6n-3):nth-child(3n-2) .link-modal .link-modal-close, .section-tiles .tile-row .tile-container:nth-child(6n-4):nth-child(3n-1) .link-modal .link-modal-close, .section-tiles .tile-row .tile-container:nth-child(6n-4):nth-child(3n-2) .link-modal .link-modal-close, .section-tiles .tile-row .tile-container:nth-child(6n-5):nth-child(3n-1) .link-modal .link-modal-close, .section-tiles .tile-row .tile-container:nth-child(6n-5):nth-child(3n-2) .link-modal .link-modal-close {
        right: 12px;
        left: auto; }
    .section-tiles .tile-row .tile-container:nth-child(6n-3):nth-child(3n-3) .link-modal, .section-tiles .tile-row .tile-container:nth-child(6n-4):nth-child(3n-3) .link-modal, .section-tiles .tile-row .tile-container:nth-child(6n-5):nth-child(3n-3) .link-modal {
      padding: 47px 111px 47px 65px;
      left: auto;
      right: calc(50% - 74px); }
      .section-tiles .tile-row .tile-container:nth-child(6n-3):nth-child(3n-3) .link-modal .link-modal-close, .section-tiles .tile-row .tile-container:nth-child(6n-4):nth-child(3n-3) .link-modal .link-modal-close, .section-tiles .tile-row .tile-container:nth-child(6n-5):nth-child(3n-3) .link-modal .link-modal-close {
        right: auto;
        left: 12px; }
    @media only screen and (max-width: 768px) {
      .section-tiles .tile-row .tile-container .link-modal {
        padding: 47px 30px 47px 30px !important;
        left: 0 !important;
        right: auto !important;
        width: 100% !important; }
        .section-tiles .tile-row .tile-container .link-modal .link-modal-close {
          right: 12px !important;
          left: auto !important; }
        .section-tiles .tile-row .tile-container .link-modal .square {
          display: none; } }

.link-modal {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 4% auto;
  width: 60%;
  max-width: 960px;
  height: auto;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.18);
  z-index: 1111;
  padding: 48px 2rem 44px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 19px;
  background-color: #ffffff;
  /* Smartphones (portrait and landscape) ----------- */ }
  .link-modal h3, .link-modal h4, .link-modal h5 {
    text-transform: uppercase; }
  .link-modal .link-modal-close {
    width: 11px;
    height: 11px;
    display: block;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer; }
    .link-modal .link-modal-close svg {
      display: block;
      width: 100%; }
  @media only screen and (max-width: 768px) {
    .link-modal {
      position: absolute;
      top: 80px;
      width: 100%;
      margin: 0;
      padding: 30px !important; }
      .link-modal .panel {
        display: none; } }

.section-external-link {
  margin-top: 130px;
  margin-bottom: 75px;
  position: relative; }
  .section-external-link.extended .feat .obraz img {
    display: block;
    width: 100%;
    height: auto; }
  .section-external-link.extended .feat .tytul {
    font-size: 36px;
    line-height: 1.2;
    font-family: "hans_kendrick_heavy", sans-serif;
    text-transform: uppercase; }
  .section-external-link .external-button {
    display: block;
    width: fit-content;
    text-transform: uppercase;
    font-family: "hans_kendrick_heavy", sans-serif;
    font-size: 21px;
    line-height: 17px;
    border: 3px solid #000000;
    margin: 0 auto;
    padding: 27px 24px;
    padding-right: 100px;
    position: relative;
    cursor: pointer;
    max-width: 360px; }
    .section-external-link .external-button button {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: none;
      outline: none;
      border: none;
      font-size: inherit;
      font-family: "hans_kendrick_heavy", sans-serif;
      text-align: left;
      color: inherit;
      line-height: 1.2;
      font-weight: inherit;
      text-transform: uppercase; }
    .section-external-link .external-button a, .section-external-link .external-button span, .section-external-link .external-button svg {
      display: inline-block;
      line-height: 1.1; }
    .section-external-link .external-button svg {
      position: absolute;
      height: calc(100% - 20px);
      top: 10px;
      right: 27px;
      transform: rotate(180deg); }
  .section-external-link.extended .link-modal {
    position: absolute;
    width: 100%;
    height: auto;
    top: 53%;
    left: 0; }

.disclaimer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  padding: 33px 66px 32px;
  font-size: 16px;
  color: #000000;
  background-color: #ffffff;
  box-sizing: border-box;
  /* Smartphones (portrait and landscape) ----------- */ }
  .disclaimer.hidden {
    display: none; }
  .disclaimer p, .disclaimer a {
    display: inline-block;
    color: #000000;
    margin: 0; }
  .disclaimer a {
    text-decoration: underline;
    text-transform: uppercase;
    font-family: "hans_kendrick_heavy", sans-serif;
    margin-left: 20px; }
  @media only screen and (max-width: 768px) {
    .disclaimer {
      text-align: center; }
      .disclaimer p, .disclaimer a {
        display: block;
        margin-top: 12px; } }

.page-header {
  margin-bottom: 0;
  padding-left: 63px;
  width: 65%;
  /* Smartphones (portrait and landscape) ----------- */ }
  @media only screen and (max-width: 767px) {
    .page-header {
      width: 100%; } }
  .page-header h2 {
    text-transform: uppercase;
    font-size: 38px;
    line-height: 1;
    margin-bottom: 15px;
    margin-top: 80px; }
  .page-header p {
    margin: 0;
    line-height: 20px;
    font-family: "hans_kendrick", sans-serif;
    font-size: 14px; }
    .page-header p.strong {
      font-size: 15px;
      line-height: 19px;
      text-transform: uppercase;
      font-family: "hans_kendrick_heavy", sans-serif;
      margin-bottom: 11px; }
  .page-header > p {
    width: 66%; }
    @media only screen and (max-width: 767px) {
      .page-header > p {
        width: 100%; } }
  .page-header + div.row {
    margin-top: 60px !important; }
  .page-header + figure + div.row {
    margin-top: 60px !important; }
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    .page-header h2 {
      margin-top: 280px; } }
  @media only screen and (max-width: 768px) {
    .page-header {
      padding-left: 2rem;
      padding-right: 2rem;
      box-sizing: border-box; }
      .page-header h2 {
        text-align: center;
        padding-left: unset;
        margin-top: 170px;
        font-size: 32px; }
      .page-header p {
        text-align: center; } }

.popup-overlay {
  position: absolute;
  top: 32%;
  z-index: 1000;
  vertical-align: middle; }
  .popup-overlay .popup-content {
    position: relative;
    background-color: rgba(255, 255, 255, 0.87);
    width: 600px;
    height: auto;
    max-width: 678px;
    min-height: 366px;
    padding-top: 60px;
    padding-bottom: 60px;
    box-sizing: border-box;
    color: #000000;
    box-shadow: -8px 8px 18px rgba(0, 0, 0, 0.16);
    margin: 0 auto; }
    .popup-overlay .popup-content > nav {
      cursor: pointer;
      position: absolute;
      left: 12px;
      top: 7px;
      z-index: 1;
      font-size: 21px; }
    .popup-overlay .popup-content img {
      display: none; }
    .popup-overlay .popup-content h3 {
      font-family: "hans_kendrick", sans-serif; }
      .popup-overlay .popup-content h3 strong {
        font-family: "hans_kendrick_heavy", sans-serif; }
    .popup-overlay .popup-content blockquote {
      margin: 0; }
    .popup-overlay .popup-content > figure {
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      margin: 0;
      width: 88px;
      height: 286px;
      background-color: #c6c6c6;
      background-position: top right;
      background-repeat: no-repeat;
      background-size: cover; }

.overlay-loader {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 10000;
  color: #000000; }
  .overlay-loader .el {
    display: block;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    bottom: auto;
    margin-left: auto;
    margin-right: auto; }
    .overlay-loader .el svg {
      display: block;
      float: left;
      width: 32px;
      height: 40px;
      transform: rotate(-90deg); }

.search-container {
  width: 1000px;
  position: absolute;
  height: 100%;
  display: block;
  white-space: nowrap;
  top: 0;
  background-color: #ffffff;
  z-index: 10; }
  .search-container * {
    white-space: normal; }
  .search-container.align-left {
    left: 48px; }
  .search-container.align-right {
    right: 48px; }
  .search-container form {
    display: inline-block;
    width: auto;
    vertical-align: top; }
    .search-container form input {
      display: block;
      width: 100%;
      line-height: 1;
      padding: 0;
      margin: 0;
      border: none;
      outline: none;
      text-indent: 1em; }
  .search-container figure {
    margin: 0;
    display: inline-block;
    cursor: pointer;
    position: relative;
    height: 100%; }
    .search-container figure > div {
      height: 100%;
      margin: 0;
      padding: 0; }
      .search-container figure > div.close-search {
        padding: 5px; }
    .search-container figure svg {
      display: block;
      width: 100%;
      height: auto; }

.input {
  padding: 0;
  /* Smartphones (portrait and landscape) ----------- */ }
  @media only screen and (max-width: 768px) {
    .input {
      height: 70px; }
      .input input {
        padding-left: 1em !important; } }

.label {
  padding-left: 0; }
  .label label[for="content-search"] {
    cursor: pointer;
    text-transform: uppercase;
    padding: 29px 37px;
    display: inline-block;
    font-size: 22px;
    font-family: "hans_kendrick_heavy", sans-serif;
    line-height: 1;
    color: #ffffff;
    background-color: #000000; }

.results {
  list-style: none;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 73px;
  z-index: 10;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */ }
  @media only screen and (max-width: 768px) {
    .results {
      top: 70px !important; } }
  .results.left {
    left: 0; }
  .results.right {
    right: 0; }
  .results li {
    display: block;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 0 40px;
    font-size: 13px;
    white-space: nowrap;
    width: auto;
    cursor: pointer; }
    .results li strong {
      font-size: 18px;
      line-height: 33px;
      display: inline-block; }
    .results li:first-child {
      padding-top: 37px; }
    .results li:last-child {
      padding-bottom: 37px; }
    .results li a {
      color: #ffffff; }
  @media only screen and (max-width: 768px) {
    .results {
      width: 100%;
      right: auto;
      left: 0; } }

.search-label {
  display: inline-block;
  font-size: 22px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #000000;
  padding-left: 65px;
  padding-right: 65px;
  height: 89px; }

.list h3 {
  padding: 44px 0 20px;
  margin: 0;
  margin-bottom: 25px;
  text-transform: uppercase;
  position: relative; }

.list ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  .list ul li {
    line-height: 33px; }
    .list ul li button {
      appearance: none;
      background: none;
      outline: none;
      border: none;
      margin: 0;
      padding: 0;
      font-size: 18px;
      line-height: 1;
      cursor: pointer;
      color: #000000;
      text-align: left;
      /* Smartphones (portrait and landscape) ----------- */ }
      .list ul li button:hover {
        -webkit-transition: color 0.16s ease-out;
        -moz-transition: color 0.16s ease-out;
        -ms-transition: color 0.16s ease-out;
        -o-transition: color 0.16s ease-out;
        transition: color 0.16s ease-out;
        color: #0a675f; }
      @media only screen and (max-width: 768px) {
        .list ul li button {
          text-align: center; } }

.list.themed h3 {
  padding: 24px 36px 23px;
  font-size: 18px;
  line-height: 1;
  color: #ffffff; }
  .list.themed h3 span {
    white-space: nowrap; }

.list.themed ul li {
  line-height: 39px;
  font-size: 18px;
  padding-left: 24px;
  position: relative;
  cursor: pointer;
  /* Smartphones (portrait and landscape) ----------- */ }
  .list.themed ul li:last-child {
    margin-bottom: 24px; }
    .list.themed ul li:last-child.item-takko {
      margin-bottom: 18px; }
  .list.themed ul li.placeholder {
    pointer-events: none; }
  .list.themed ul li .modal {
    top: 40px;
    left: 0;
    z-index: 10;
    width: 458px;
    pointer-events: none; }
    .list.themed ul li .modal * {
      pointer-events: none; }
    .list.themed ul li .modal h3 {
      margin: 0;
      font-size: 19px;
      line-height: 1.2;
      text-transform: uppercase;
      color: #000000;
      padding: 0; }
    .list.themed ul li .modal .phone {
      margin-bottom: 38px !important; }
      .list.themed ul li .modal .phone.reduced {
        margin-bottom: 12px !important; }
    .list.themed ul li .modal nav {
      display: none; }
  .list.themed ul li.cat-uslugi .modal {
    left: auto;
    right: 0; }
  @media only screen and (max-width: 768px) {
    .list.themed ul li .modal {
      display: none !important; } }

.pagination {
  list-style: none;
  margin: 0;
  margin-bottom: 65px; }
  .pagination::selection {
    color: inherit;
    background: none; }
  .pagination li {
    display: inline-block;
    margin: 0 3px;
    cursor: pointer; }
    .pagination li::selection {
      color: inherit;
      background: none; }
    .pagination li.nav {
      font-size: 11px; }
    .pagination li.active {
      font-family: "hans_kendrick_heavy", sans-serif; }
    .pagination li.disabled {
      visibility: hidden; }

html.lightbox-open {
  overflow: hidden;
  position: fixed;
  width: 100%; }

.lightbox-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(27, 92, 82, 0.86);
  top: 0px;
  left: 0px;
  z-index: 100; }

.lightbox-btn-left,
.lightbox-btn-right,
.lightbox-btn-close,
.lightbox-title-content,
.lightbox-modifiers-box {
  transition: opacity 200ms ease; }

@media (max-width: 767px) {
  .hide-controls .lightbox-btn-left,
  .hide-controls .lightbox-btn-right {
    opacity: 0;
    pointer-events: none; } }

@media (max-height: 599px) {
  .hide-controls .lightbox-btn-close,
  .hide-controls .lightbox-title-content,
  .hide-controls .lightbox-modifiers-box {
    opacity: 0;
    pointer-events: none; } }

.lightbox-btn-close {
  position: fixed;
  left: 0px;
  z-index: 2; }

.lightbox-btn-right {
  position: absolute;
  bottom: -3px;
  margin-top: -1.5em;
  right: 45%; }

.lightbox-btn-left {
  position: absolute;
  bottom: -3px;
  margin-top: -1.5em;
  left: 45%; }

@media (min-width: 769px) {
  .lightbox-btn-right {
    padding-right: 10px; }
  .lightbox-btn-left {
    padding-left: 10px; } }

.lightbox-title-content {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 0;
  text-align: center;
  padding: 10px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  line-height: 1.42857143; }

.lightbox-title {
  color: white;
  font-weight: 600;
  font-size: 16px; }

.lightbox-description {
  color: #C5C5C5;
  font-size: 14px;
  display: none; }

.lightbox-transition-image-appear {
  opacity: 0.1; }

.lightbox-transition-image-appear.lightbox-transition-image-appear-active {
  opacity: 1;
  transition: opacity .3s ease-in; }

.lightbox-transition-image-enter-right {
  transform: translate(100%); }

.lightbox-transition-image-enter-right.lightbox-transition-image-enter-right-active {
  transform: translate(0%);
  transition: transform 300ms ease-in-out; }

.lightbox-transition-image-leave-right {
  transform: translate(0%); }

.lightbox-transition-image-leave-right.lightbox-transition-image-leave-right-active {
  transform: translate(-100%);
  transition: transform 300ms ease-in-out; }

.lightbox-transition-image-enter-left {
  transform: translate(-100%); }

.lightbox-transition-image-enter-left.lightbox-transition-image-enter-left-active {
  transform: translate(0%);
  transition: transform 300ms ease-in-out; }

.lightbox-transition-image-leave-left {
  transform: translate(0%); }

.lightbox-transition-image-leave-left.lightbox-transition-image-leave-left-active {
  transform: translate(100%);
  transition: transform 300ms ease-in-out; }

.lightbox-content-center {
  width: 100%;
  height: 100%;
  position: absolute; }

.lightbox-modifiers-box {
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 1.2em;
  position: absolute;
  width: 100%;
  z-index: 1; }

@media (min-height: 600px) {
  .lightbox-modifiers-box {
    background-color: transparent; } }

@media (min-width: 769px) {
  .lightbox-image-container {
    margin: 0 5em; } }

.lightbox-image-container {
  overflow: hidden;
  height: calc(100% - 102px);
  margin-top: 40px; }

.lightbox-image {
  height: 100%;
  background-repeat: no-repeat; }

.lightbox-image.moving {
  transition: none; }

.lightbox-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  fill: white;
  animation: rotating 2s linear infinite; }

@keyframes rotating {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(-360deg); } }

.lightbox-btn {
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  background: none;
  border: 1px solid transparent;
  fill: white;
  opacity: 0.8;
  outline: none;
  margin: 2px 2px;
  padding: 0 0;
  -webkit-tap-highlight-color: #ffffff00; }

.lightbox-btn:hover {
  opacity: 1;
  transition: opacity 200ms ease; }

.lightbox-btn--ripple circle {
  fill: none; }

.lightbox-btn--ripple:hover circle {
  transform: scale(3);
  transform-origin: 33% 33%;
  fill: #1a665a;
  transition: transform 200ms ease; }

.gallery-row-container {
  position: relative;
  /* Smartphones (portrait and landscape) ----------- */ }
  .gallery-row-container.standalone {
    padding-top: 60px;
    padding-bottom: calc(60px - 2rem);
    margin: 35px 0 114px 0; }
  @media only screen and (max-width: 768px) {
    .gallery-row-container {
      padding: 0;
      margin: 0; } }
  .gallery-row-container > .col {
    padding-left: 0;
    padding-right: 0; }
  @media only screen and (min-width: 992px) {
    .gallery-row-container {
      margin-right: -1rem;
      margin-left: -1rem; }
      .gallery-row-container > .col {
        padding-right: 1rem;
        padding-left: 1rem; } }
  .gallery-row-container .fota-wrapper {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 2rem;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .gallery-row-container .fota-wrapper {
        padding-bottom: 0; } }
  .gallery-row-container .fota {
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100%; }
  .gallery-row-container .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 62%;
    height: 100%;
    display: none; }
    @media only screen and (min-width: 992px) {
      .gallery-row-container .bg {
        display: block; } }
  .gallery-row-container .lightbox-container {
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem; }
    .gallery-row-container .lightbox-container img {
      display: block;
      width: 100% !important;
      height: auto !important;
      padding: 0;
      margin: 0;
      cursor: pointer; }
  .gallery-row-container .expand {
    padding: 42px 0 73px;
    text-align: center;
    text-decoration: underline;
    text-transform: lowercase;
    font-size: 22px;
    font-family: "hans_kendrick", sans-serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    outline: none;
    border: none;
    cursor: pointer; }

.lightbox-gallery-overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10001; }
  .lightbox-gallery-overlay .lightbox-gallery-container {
    display: block;
    width: 80vw;
    max-width: 1340px;
    height: 80vh;
    margin: 10vh auto 10vh;
    position: relative;
    animation: fadein .3s;
    /* Smartphones (portrait and landscape) ----------- */ }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
    .lightbox-gallery-overlay .lightbox-gallery-container figcaption {
      margin: 0;
      padding: 0;
      position: absolute;
      top: -22px;
      right: calc(-29% + 30px);
      width: 29%;
      z-index: 12; }
      .lightbox-gallery-overlay .lightbox-gallery-container figcaption nav {
        color: #ffffff;
        font-family: "hans_kendrick", sans-serif;
        font-size: 24px;
        line-height: 1;
        display: block;
        text-align: right;
        margin-bottom: 20px;
        cursor: pointer;
        /* Smartphones (portrait and landscape) ----------- */ }
        @media only screen and (max-width: 768px) {
          .lightbox-gallery-overlay .lightbox-gallery-container figcaption nav {
            position: fixed;
            top: 12px;
            right: 14px; } }
      .lightbox-gallery-overlay .lightbox-gallery-container figcaption article {
        padding: 59px 2em 67px;
        text-align: center;
        box-sizing: border-box;
        /* Smartphones (portrait and landscape) ----------- */ }
        .lightbox-gallery-overlay .lightbox-gallery-container figcaption article h3 {
          text-transform: uppercase;
          font-size: 15px;
          line-height: 1.2;
          margin-bottom: 59px; }
        @media only screen and (max-width: 768px) {
          .lightbox-gallery-overlay .lightbox-gallery-container figcaption article {
            padding: 26px 2em 26px;
            max-height: 200px;
            overflow-y: scroll; }
            .lightbox-gallery-overlay .lightbox-gallery-container figcaption article h3 {
              margin-bottom: 26px; } }
        .lightbox-gallery-overlay .lightbox-gallery-container figcaption article.title-only {
          padding: 59px 2em 59px; }
          .lightbox-gallery-overlay .lightbox-gallery-container figcaption article.title-only h3 {
            margin: 0; }
    .lightbox-gallery-overlay .lightbox-gallery-container figure {
      margin: 0 auto;
      padding: 0;
      width: 100%;
      max-width: 950px;
      position: relative;
      height: 82vh; }
      .lightbox-gallery-overlay .lightbox-gallery-container figure .slick-slider {
        height: 100%;
        width: auto; }
        .lightbox-gallery-overlay .lightbox-gallery-container figure .slick-slider .slick-arrow {
          top: auto;
          /* Smartphones (portrait and landscape) ----------- */ }
          .lightbox-gallery-overlay .lightbox-gallery-container figure .slick-slider .slick-arrow.slick-next {
            right: 45%;
            bottom: -77px; }
          .lightbox-gallery-overlay .lightbox-gallery-container figure .slick-slider .slick-arrow.slick-prev {
            left: 45%;
            bottom: -80px; }
          @media only screen and (max-width: 768px) {
            .lightbox-gallery-overlay .lightbox-gallery-container figure .slick-slider .slick-arrow.slick-next {
              right: -19px; }
            .lightbox-gallery-overlay .lightbox-gallery-container figure .slick-slider .slick-arrow.slick-prev {
              left: -19px; } }
        .lightbox-gallery-overlay .lightbox-gallery-container figure .slick-slider .slick-list {
          margin: 0 auto;
          overflow: visible; }
        .lightbox-gallery-overlay .lightbox-gallery-container figure .slick-slider .slick-track {
          height: 82vh;
          z-index: 1; }
        .lightbox-gallery-overlay .lightbox-gallery-container figure .slick-slider .slick-slide {
          height: 100%;
          width: auto; }
          .lightbox-gallery-overlay .lightbox-gallery-container figure .slick-slider .slick-slide > div {
            text-align: center; }
          .lightbox-gallery-overlay .lightbox-gallery-container figure .slick-slider .slick-slide img {
            height: 82vh;
            width: auto !important;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.3); }
    @media only screen and (min-width: 769px) and (max-width: 1440px) {
      .lightbox-gallery-overlay .lightbox-gallery-container figcaption {
        margin: 0;
        padding: 0;
        position: absolute;
        top: -22px;
        right: calc(-14% + 30px);
        width: 29%;
        z-index: 12; }
        .lightbox-gallery-overlay .lightbox-gallery-container figcaption nav {
          color: #ffffff;
          font-family: "hans_kendrick", sans-serif;
          font-size: 24px;
          line-height: 1;
          display: block;
          text-align: right;
          margin-bottom: 20px;
          cursor: pointer; }
        .lightbox-gallery-overlay .lightbox-gallery-container figcaption article {
          padding: 59px 2em 67px;
          text-align: center;
          box-sizing: border-box; }
          .lightbox-gallery-overlay .lightbox-gallery-container figcaption article h3 {
            text-transform: uppercase;
            font-size: 15px;
            line-height: 1.2;
            margin-bottom: 59px; } }
    @media only screen and (max-width: 768px) {
      .lightbox-gallery-overlay .lightbox-gallery-container figcaption {
        margin: 0;
        padding: 0;
        position: absolute;
        top: auto;
        bottom: -7%;
        left: -16px;
        width: calc(100% + 36px);
        z-index: 12; }
      .lightbox-gallery-overlay .lightbox-gallery-container figure {
        height: 50vh; }
        .lightbox-gallery-overlay .lightbox-gallery-container figure .slick-slider .slick-track {
          height: 50vh; }
        .lightbox-gallery-overlay .lightbox-gallery-container figure .slick-slider .slick-slide img {
          height: 50vh; } }

.hours-row-container {
  margin: 0;
  padding: 0 8%;
  padding-right: 3%;
  margin-bottom: 60px; }
  @media only screen and (min-width: 320px) and (max-width: 991px) {
    .hours-row-container {
      padding: 0; } }
  .hours-row-container .col, .hours-row-container .row {
    margin: 0;
    padding: 0; }
  .hours-row-container > .col > h3 {
    border-bottom: 2px solid #f19231;
    padding-bottom: 0;
    width: 80%; }
    @media only screen and (min-width: 320px) and (max-width: 991px) {
      .hours-row-container > .col > h3 {
        width: 100%; } }
  .hours-row-container > .col:last-child ul li:last-child {
    width: 80%;
    border-top: 2px solid #f8d448; }
    @media only screen and (min-width: 320px) and (max-width: 991px) {
      .hours-row-container > .col:last-child ul li:last-child {
        width: 100%; } }
  .hours-row-container ul {
    padding: 0;
    margin: 0;
    list-style: none; }
    .hours-row-container ul li {
      font-size: 14px;
      line-height: 16px;
      height: 44px;
      vertical-align: middle;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      text-align: left; }
      .hours-row-container ul li:last-child {
        border-top: 2px solid #f8d448; }
      .hours-row-container ul li header {
        display: none;
        width: 50%;
        padding-left: 1em;
        box-sizing: border-box;
        text-align: left; }
        @media only screen and (min-width: 320px) and (max-width: 991px) {
          .hours-row-container ul li header {
            display: inline-block; } }
      .hours-row-container ul li span {
        padding-left: 1em; }
        .hours-row-container ul li span.small {
          font-size: 11px;
          text-transform: none;
          padding-left: 0; }
        @media only screen and (min-width: 320px) and (max-width: 991px) {
          .hours-row-container ul li span {
            display: inline-block; } }
    @media only screen and (min-width: 320px) and (max-width: 991px) {
      .hours-row-container ul {
        margin-bottom: 33px; } }
  .hours-row-container .labels > h3 {
    border-bottom: 2px solid #ffffff;
    padding-bottom: 3px;
    width: 100%; }
  .hours-row-container .labels ul li {
    font-family: "hans_kendrick_heavy", sans-serif;
    padding-right: 2em;
    padding-left: 1em; }
    .hours-row-container .labels ul li:last-child {
      border-top: 2px solid #ffffff; }
  @media only screen and (min-width: 320px) and (max-width: 991px) {
    .hours-row-container .labels {
      display: none; } }

.form-container {
  position: fixed;
  top: 35vh;
  bottom: auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90vw;
  max-width: 620px;
  box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.12);
  z-index: 1000;
  background-color: #ffffff;
  padding: 48px 0 26px;
  opacity: 1;
  /* Smartphones (portrait and landscape) ----------- */ }
  @media only screen and (max-width: 768px) {
    .form-container {
      top: 20vh; } }
  .form-container nav {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    display: block;
    width: 14px;
    height: 14px; }
    .form-container nav svg {
      display: block; }
  .form-container h2, .form-container h3 {
    display: block;
    line-height: 1;
    text-transform: uppercase; }
  .form-container h2 {
    font-size: 33px;
    margin-bottom: 5px;
    margin-top: 0; }
  .form-container h3 {
    margin-top: 0;
    font-size: 15px;
    margin-bottom: 22px; }
  .form-container input[type="text"] {
    background: none;
    outline: none;
    border: none;
    border-bottom: 2px solid #0a675f;
    width: 100%;
    display: inline-block;
    padding-bottom: 10px;
    background: none;
    outline: none;
    border: none;
    border-bottom: 2px solid #0a675f;
    width: 100%;
    display: inline-block;
    padding-bottom: 10px; }
  .form-container .head > .col {
    text-align: left;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .form-container .head > .col {
        text-align: center; } }
  .form-container .text {
    margin-bottom: 36px;
    font-size: 15px;
    /* Smartphones (portrait and landscape) ----------- */ }
    .form-container .text label, .form-container .text button {
      text-transform: uppercase;
      font-family: "hans_kendrick_heavy", sans-serif; }
    .form-container .text > .col {
      text-align: right;
      /* Smartphones (portrait and landscape) ----------- */ }
      @media only screen and (max-width: 768px) {
        .form-container .text > .col {
          text-align: left; } }
      .form-container .text > .col.submit {
        text-align: left;
        /* Smartphones (portrait and landscape) ----------- */ }
        @media only screen and (max-width: 768px) {
          .form-container .text > .col.submit {
            text-align: right; } }
    .form-container .text figcaption {
      display: none; }
    .form-container .text.error {
      position: relative; }
      .form-container .text.error input[type="text"] {
        border-bottom-color: red; }
      .form-container .text.error figcaption {
        position: absolute;
        bottom: -19px;
        left: 1rem;
        display: block;
        color: red;
        margin: 0;
        padding: 0;
        font-size: 13px; }
    .form-container .text.success {
      color: #0a675f; }
      .form-container .text.success > .col {
        text-align: left;
        /* Smartphones (portrait and landscape) ----------- */ }
        @media only screen and (max-width: 768px) {
          .form-container .text.success > .col {
            text-align: center; } }
    @media only screen and (max-width: 768px) {
      .form-container .text {
        padding-left: 2em; }
        .form-container .text button {
          margin-top: 20px; } }
  .form-container .checkbox {
    font-size: 10px;
    line-height: 1.2;
    /* Smartphones (portrait and landscape) ----------- */ }
    .form-container .checkbox.error input {
      outline: 1px solid red; }
    @media only screen and (max-width: 768px) {
      .form-container .checkbox {
        padding-left: 2em; } }
  .form-container.contact {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 63px;
    padding-right: 63px;
    box-sizing: border-box;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .form-container.contact {
        padding-left: 2rem;
        padding-right: 2rem; } }
    .form-container.contact .checkbox {
      margin-bottom: 10px; }
      .form-container.contact .checkbox:last-child {
        margin-bottom: 0; }
    .form-container.contact .head > .col {
      text-align: center; }
    .form-container.contact .text > .col input {
      font-size: 16px;
      line-height: 1;
      text-transform: none;
      font-family: "hans_kendrick", sans-serif;
      text-align: center;
      color: #000000; }
      .form-container.contact .text > .col input::placeholder {
        color: #989897;
        font-family: "hans_kendrick_heavy", sans-serif;
        text-transform: uppercase; }
    .form-container.contact .text > .col.text-input {
      margin-top: 36px; }
    .form-container.contact .text > .col.textarea {
      margin-top: 36px;
      margin-bottom: 25px; }
      .form-container.contact .text > .col.textarea label {
        display: block;
        font-size: 16px;
        line-height: 1;
        text-transform: uppercase;
        font-family: "hans_kendrick_heavy", sans-serif;
        text-align: center;
        color: #989897;
        margin-bottom: 15px; }
      .form-container.contact .text > .col.textarea textarea {
        display: block;
        width: 100%;
        border: none;
        outline: none;
        margin: 0;
        background-color: #d2d2d2;
        color: #000000;
        padding: 2rem;
        text-align: left;
        box-sizing: border-box; }
    .form-container.contact .text > .col.error {
      position: relative; }
      .form-container.contact .text > .col.error input[type="text"] {
        border-bottom-color: red; }
      .form-container.contact .text > .col.error figcaption {
        position: absolute;
        width: 90%;
        text-align: center;
        bottom: -19px;
        left: 1rem;
        display: block;
        color: red;
        margin: 0;
        padding: 0;
        font-size: 13px; }
    .form-container.contact .text > .col.submit {
      margin-bottom: 26px; }
      .form-container.contact .text > .col.submit button {
        width: 100%; }
    .form-container.contact .text.success > .col {
      margin-top: 36px;
      text-align: center; }
    .form-container.contact.sent {
      height: 100%; }

.player-container {
  position: fixed;
  margin-top: 0;
  margin-left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #0a675f;
  z-index: 98;
  color: #f5f1f1;
  padding: 0;
  box-sizing: border-box; }
  .player-container header {
    position: absolute;
    bottom: 110px;
    right: 29px;
    font-family: "hans_kendrick_heavy", sans-serif;
    z-index: 10; }
    .player-container header h4 {
      font-size: 44px;
      line-height: 65px;
      alignment-baseline: top;
      text-transform: uppercase; }
    .player-container header ul li {
      font-size: 11px;
      line-height: 14px;
      font-family: "hans_kendrick", sans-serif; }
  .player-container nav.video-close {
    position: absolute;
    width: 24px;
    height: 26px;
    z-index: 10;
    display: block;
    right: 80px;
    top: 22px; }
    .player-container nav.video-close button {
      -webkit-appearance: none;
      cursor: pointer;
      background: none;
      border: none;
      outline: none;
      font-family: "hans_kendrick", sans-serif;
      font-size: 24px;
      line-height: 1;
      color: #ffffff;
      text-transform: uppercase; }
  .player-container nav.video-controls {
    pointer-events: all;
    position: absolute;
    bottom: 26px;
    left: 42px;
    z-index: 10;
    display: block; }
    .player-container nav.video-controls ul {
      margin: 0;
      padding: 0;
      width: 180px;
      height: 39px;
      display: block; }
      .player-container nav.video-controls ul li {
        display: inline-block;
        box-sizing: border-box;
        width: 33px;
        text-align: center;
        cursor: pointer;
        opacity: 1;
        margin-right: 26px; }
        .player-container nav.video-controls ul li:last-child {
          margin-right: 0; }
        .player-container nav.video-controls ul li:hover {
          opacity: .6;
          -webkit-transition: opacity 0.3s ease-in-out;
          -moz-transition: opacity 0.3s ease-in-out;
          -ms-transition: opacity 0.3s ease-in-out;
          -o-transition: opacity 0.3s ease-in-out;
          transition: opacity 0.3s ease-in-out; }
  .player-container .stage {
    pointer-events: none;
    overflow: hidden;
    display: block;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    position: relative;
    box-shadow: 0 0 26px rgba(0, 0, 0, 0.4); }
    @media only screen and (max-width: 1600px) {
      .player-container .stage {
        width: calc(100vw - 400px); } }
    .player-container .stage .container-pic {
      width: 100%;
      height: auto; }
    .player-container .stage .vid iframe {
      z-index: 9;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
    .player-container .stage.mobile {
      margin: 0;
      width: 100%;
      height: 100%; }
      @media screen and (orientation: portrait) {
        .player-container .stage.mobile .vid iframe {
          z-index: 9;
          width: 126vw;
          min-width: auto;
          height: auto;
          min-height: 40vh;
          position: absolute;
          top: 13%;
          left: 50%;
          transform: translate(-50%, 50%); } }

.newsletter-container {
  position: fixed;
  top: 10vh;
  bottom: auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90vw;
  max-width: 620px;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.12);
  z-index: 1000;
  background-color: #ffffff;
  padding: 48px 0 26px 31px;
  opacity: 1;
  /* Smartphones (portrait and landscape) ----------- */ }
  .newsletter-container .coniqSignupTitle {
    font-weight: bold; }
  .newsletter-container #coniqLoadingAnimation {
    text-align: center; }
  @media only screen and (max-width: 768px) {
    .newsletter-container {
      top: 2vh; }
      .newsletter-container .coniqSignupCheckboxLabel {
        font-size: 10px; } }
  .newsletter-container nav {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    display: block;
    width: 14px;
    height: 14px; }
    .newsletter-container nav svg {
      display: block; }
  .newsletter-container h2, .newsletter-container h3, .newsletter-container .coniqSignupTitle {
    display: block;
    line-height: 1;
    text-transform: uppercase; }
  .newsletter-container h2 {
    font-size: 33px;
    margin-bottom: 5px;
    margin-top: 0; }
  .newsletter-container h3, .newsletter-container .coniqSignupTitle {
    margin-top: 0;
    font-size: 15px;
    margin-bottom: 22px; }
  .newsletter-container input[type="text"] {
    background: none;
    outline: none;
    border: none;
    border-bottom: 2px solid #0a675f;
    width: 100%;
    display: inline-block;
    padding-bottom: 10px; }
  .newsletter-container .head > .col {
    text-align: left;
    /* Smartphones (portrait and landscape) ----------- */ }
    @media only screen and (max-width: 768px) {
      .newsletter-container .head > .col {
        text-align: center; } }
  .newsletter-container .text {
    margin-bottom: 36px;
    font-size: 15px;
    /* Smartphones (portrait and landscape) ----------- */ }
    .newsletter-container .text label, .newsletter-container .text button {
      text-transform: uppercase;
      font-family: "hans_kendrick_heavy", sans-serif; }
    .newsletter-container .text > .col {
      text-align: right;
      /* Smartphones (portrait and landscape) ----------- */ }
      @media only screen and (max-width: 768px) {
        .newsletter-container .text > .col {
          text-align: left; } }
      .newsletter-container .text > .col.submit {
        text-align: left;
        /* Smartphones (portrait and landscape) ----------- */ }
        @media only screen and (max-width: 768px) {
          .newsletter-container .text > .col.submit {
            text-align: right; } }
    .newsletter-container .text figcaption {
      display: none; }
    .newsletter-container .text.error {
      position: relative; }
      .newsletter-container .text.error input[type="text"] {
        border-bottom-color: red; }
      .newsletter-container .text.error figcaption {
        position: absolute;
        bottom: -19px;
        left: 1rem;
        display: block;
        color: red;
        margin: 0;
        padding: 0;
        font-size: 13px; }
      .newsletter-container .text.error.response-msg span {
        color: red;
        text-align: left;
        display: block; }
        .newsletter-container .text.error.response-msg span a {
          color: red;
          text-align: left; }
    .newsletter-container .text.success {
      color: #0a675f; }
      .newsletter-container .text.success > .col {
        text-align: left;
        /* Smartphones (portrait and landscape) ----------- */ }
        @media only screen and (max-width: 768px) {
          .newsletter-container .text.success > .col {
            text-align: center; } }
    @media only screen and (max-width: 768px) {
      .newsletter-container .text {
        padding-left: 2em; }
        .newsletter-container .text button {
          margin-top: 20px; } }
  .newsletter-container .checkbox {
    font-size: 10px;
    line-height: 1.2;
    margin-bottom: 10px;
    /* Smartphones (portrait and landscape) ----------- */ }
    .newsletter-container .checkbox a {
      margin-left: 10px; }
    .newsletter-container .checkbox.error input {
      outline: 1px solid red; }
    @media only screen and (max-width: 768px) {
      .newsletter-container .checkbox {
        padding-left: 2em; } }

.season-block {
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  top: 15px;
  right: 1px;
  width: 33.333%;
  height: 331px;
  z-index: 1; }
  @media only screen and (max-width: 1280px) and (min-width: 769px) {
    .season-block {
      top: 215px; } }

.back-badge {
  position: fixed;
  left: 0;
  top: 200px;
  z-index: 10;
  display: block;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 17px;
  padding-right: 22px;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.18); }
  .back-badge svg {
    display: block;
    height: 45px;
    width: auto; }
  .back-badge:hover {
    padding-left: 52px;
    -webkit-transition: padding-left 0.18s ease-in-out;
    -moz-transition: padding-left 0.18s ease-in-out;
    -ms-transition: padding-left 0.18s ease-in-out;
    -o-transition: padding-left 0.18s ease-in-out;
    transition: padding-left 0.18s ease-in-out; }

.modal {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 213px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.18);
  max-width: 478px;
  z-index: 11;
  padding: 48px 2rem 44px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 19px; }
  .modal h3 {
    margin: 0;
    font-size: 19px;
    line-height: 1.2;
    text-transform: uppercase; }
  .modal .col, .modal .row {
    margin: 0;
    padding: 0; }
  .modal nav.close {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 2px;
    font-size: 16px; }
  .modal nav .btn {
    position: absolute;
    left: calc(50% - 86px);
    right: 0;
    margin-right: auto;
    bottom: -17px;
    padding: 7px 26px 6px; }
    @media only screen and (-ms-high-contrast: none) {
      .modal nav .btn {
        width: 45%; } }
  .modal section {
    text-align: center;
    margin: 0;
    padding: 0; }
  .modal .phone {
    margin-bottom: 38px; }
    .modal .phone.reduced {
      margin-bottom: 12px; }
    .modal .phone span {
      display: inline-block;
      vertical-align: middle; }
    .modal .phone .email .info {
      white-space: nowrap; }
    .modal .phone .icon span {
      text-align: right; }
      .modal .phone .icon span svg {
        position: static;
        display: inline-block;
        width: 21px !important;
        height: 21px !important; }
    .modal .phone .info {
      text-align: left; }

.slick-slider {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  z-index: 11;
  /* Smartphones (portrait and landscape) ----------- */ }
  .slick-slider .slide-video {
    appearance: none;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    cursor: pointer; }
  .slick-slider .slick-slide img {
    width: 100%; }
  .slick-slider .slick-arrow {
    position: absolute;
    z-index: 4;
    top: calc(50% - 14px);
    cursor: pointer; }
    .slick-slider .slick-arrow.slick-next {
      right: 18px;
      transform: rotate(180deg); }
    .slick-slider .slick-arrow.slick-prev {
      left: 18px; }
  .slick-slider .slick-dots {
    padding: 0;
    margin: 0;
    position: absolute;
    left: 24px;
    bottom: -45px;
    text-align: center; }
    .slick-slider .slick-dots li {
      display: inline-block;
      padding: 0;
      position: relative;
      overflow: hidden;
      width: 14px;
      height: 14px;
      margin-right: 24px; }
      .slick-slider .slick-dots li button {
        font-family: "hans_kendrick_heavy", sans-serif;
        font-size: 6px;
        line-height: 6px;
        text-transform: uppercase;
        text-indent: -1000em;
        text-align: center;
        background: none;
        outline: none;
        border: none;
        -webkit-appearance: none;
        cursor: pointer; }
        .slick-slider .slick-dots li button::after {
          content: "";
          display: block;
          border-radius: 6px;
          width: 12px;
          height: 12px;
          border: 1px solid #000;
          background-color: transparent;
          position: absolute;
          top: 0;
          left: 0;
          box-sizing: border-box; }
      .slick-slider .slick-dots li.slick-active button::after {
        content: "";
        background-color: #000; }
  @media only screen and (max-width: 768px) {
    .slick-slider .slick-dots {
      left: 46%; } }

.basicLightbox {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  opacity: .01;
  transition: opacity 0.4s ease;
  z-index: 1000;
  will-change: opacity; }
  .basicLightbox--visible {
    opacity: 1; }
  .basicLightbox__placeholder {
    max-width: 100%;
    transform: scale(0.9);
    transition: transform 0.4s ease;
    z-index: 1;
    will-change: transform; }
    .basicLightbox__placeholder > img:first-child:last-child,
    .basicLightbox__placeholder > video:first-child:last-child,
    .basicLightbox__placeholder > iframe:first-child:last-child {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      max-width: 95%;
      max-height: 95%; }
    .basicLightbox__placeholder > video:first-child:last-child,
    .basicLightbox__placeholder > iframe:first-child:last-child {
      pointer-events: auto; }
    .basicLightbox__placeholder > img:first-child:last-child,
    .basicLightbox__placeholder > video:first-child:last-child {
      width: auto;
      height: auto; }
  .basicLightbox--img .basicLightbox__placeholder,
  .basicLightbox--video .basicLightbox__placeholder,
  .basicLightbox--iframe .basicLightbox__placeholder {
    width: 100%;
    height: 100%;
    pointer-events: none; }
  .basicLightbox--visible .basicLightbox__placeholder {
    transform: scale(1); }
